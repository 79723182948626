import React from "react";
import { Link } from "react-router-dom";
import { ColumnDirective, ColumnsDirective, GridComponent, setFreezeSelection } from '@syncfusion/ej2-react-grids';
import { Filter, Inject, Resize , Edit, Page, Freeze, Selection /* VirtualScroll, Sort*/} from '@syncfusion/ej2-react-grids';
import axios from "axios";
import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { API_ADR, generateRandomString, SpinnerCentered, allowedFileFormats, takePermissionLocalStorage } from "../../ApiCenter/API_GET";
import path from "path-browserify";
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';
import $ from 'jquery';
import { SliderComponent } from '@syncfusion/ej2-react-inputs';


import './ManageTargetAssets.css'
import { ConstructionOutlined } from "@mui/icons-material";


var previewGrid
var presetsGrid
var selectedRowGrid2
var selectedRowGrid1
var languagesGrid




export const ManageTargetAssets = forwardRef((props, ref) => {

    

    const [modalSettings, setModalSettings] = useState({
        showModal: false,
        isLoading: false,
        selectedID: null,
        processing: '',
        hardforceSubmit: false
    })

    /* variable to gather data from alternative settings */
    const [dataGrid1, setDataGrid1] = useState([])
    const [dataGrid2, setDataGrid2] = useState([])
    const [dataGrid3, setDataGrid3] = useState([])
    const actorsData = useRef({});
    const [dataGridBackup, setDataGridBackup] = useState([])
    const [alternativeData, setAlternativeData] = useState([])
    const [editPreset, setEditPreset] = useState(false)
    const [selectedPreset, setSelectedPreset] = useState(-2)
    const [dupPresetName, setDupPresetName] = useState(false)
    const [presetLoaded, setPresetLoaded] = useState(-2)
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const [saveConfirmation, setSaveConfirmation] = useState(false)
    const originalPreset = useRef({})
    const presetNameRef = useRef(null)
    const presetsSelectRef = useRef(null)
    const filetypesGridRef = useRef(null)
    const languagesGridRef = useRef(null)
    const altsGridRef = useRef(null)
    const lufsPanelRef = useRef(null)
    const sourceFileTypes = useRef(null)

    const presetsList = useRef([]);
    const clientId = useRef(0);

    const [errorReport, setErrorReport] = useState('')




    useImperativeHandle(ref, () => ({
        receiveTargeID(props) {

            setModalSettings(previous => ({
                showModal: true,
                isLoading: true,
                selectedID: props.selection,
                processing: '',
                hardforceSubmit: false,
                info: props.info
            }))

        },
        removeUI() {
            setModalSettings(previous => ({
                showModal: false,
                isLoading: false,
                selectedID: null,
                processing: '',
                hardforceSubmit: false
            }))
        },
        returnValue() {
            return props
        },
    }));

    useEffect(() => {

        originalPreset.current = {}
        setDataGrid1([])
        setDataGrid2([])
        setDataGrid3([])
        setDataGridBackup([])
        setAlternativeData([])
        setErrorReport('')
        setEditPreset(false)
        setSelectedPreset(-2)
        setPresetLoaded(-2);

        const fetchUrl = async () => {

            const result = await axios.get(API_ADR(`manageTargetFile=${modalSettings.selectedID}`))

            sourceFileTypes.current = [{id: '0', file_description: ''}, ...result.data.filetypes_source];

            actorsData.current = JSON.parse(result.data?.actors ?? '{}');
            const languagesData = elaborateLanguageRulesLanguage(result.data.query1)
            const filetypesData = elaborateLanguageRules(result.data.query2)
            setDataGrid1(languagesData) 
            setDataGrid2(filetypesData)
            originalPreset.current['languages'] = JSON.stringify(languagesData);
            originalPreset.current['filetypes'] = JSON.stringify(filetypesData);
            presetsList.current = result.data.presets;
            clientId.current = result.data.client_id

            let alternativeSetting = addShowHideAlternatives(result.data.query_alt)

            const altsData = elaboratedDataPreview(result.data.query1[0], result.data.query2[0], result.data.query3, alternativeSetting, actorsData)
            setDataGrid3(altsData)
            setDataGridBackup(result.data.query3)

            originalPreset.current['alts'] = JSON.stringify(alternativeSetting);
            setAlternativeData(alternativeSetting)

            setModalSettings(previous => ({
                ...previous,
                showModal: true,
                isLoading: false,
                processing: '',
                hardforceSubmit: false
            }))


        }
        if(modalSettings.selectedID !== null){
            fetchUrl()
        }

        return () => {
            //setDataGrid3([])
        }



    }, [modalSettings.selectedID])


    const closeModal = () => {
        setModalSettings(previous => ({
            isLoading: false,
            showModal: false,
            selectedID: null,
            processing: '',
            hardforceSubmit: false
        }))
    }


    /* Show Hide alt results */
    function addShowHideAlternatives(data){

        if((data === null) || (data === undefined)){
            data = {
                expected_sufix: '_ALT',
                final_sufix: '_ALT',
                padding: '2',
                showHide: false,
                inside_main_structure: '1',
                create_subfolders: '0',
                alts_foldername: '',
                script_id: modalSettings.selectedID,
                use_for_expected: '0'
            }
        } else {
            data.showHide = false;
            //data.inside_main_structure = data.inside_main_structure === '1' ? true : false
            //data.create_subfolders = data.create_subfolders === '1' ? true : false
        }
        return data
    }

    const updateAltData = (data) => {
        setAlternativeData(previous => data)
        seletedGrid(data)
    }

    /* send data to the server */
    const saveSettings = () => {
        setErrorReport('')
        const headers = {
            'Content-Type': 'text/plain'
        };

        setModalSettings(previous => ({
            ...previous,
            processing: <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }))

        /* insert Script_id in presetGrid */

        let presetGridData_ids = presetsGrid.dataSource.map(item => {
            return { ...item, script_id: modalSettings.selectedID};
        })

        let data = {
            tableLanguages: languagesGrid.dataSource,
            tablePreset: presetGridData_ids,
            tableAlt: alternativeData
        }


        // check if the key "filetype" is written without values
        let checkPresetsSanity = checkJSONArray(data.tablePreset)
        if(checkPresetsSanity !== true){
            setErrorReport(previous => checkPresetsSanity)
            setModalSettings(previous => ({
                ...previous,
                processing: ''
            }))
            return
        }

        /* check if the filenames are duplicated between languages */
        let checkFilenamesLanguagesSanity = checkDoubleLanguages(data.tableLanguages, data.tablePreset, [dataGrid3[0]], 'languages', actorsData)
        if(checkFilenamesLanguagesSanity !== true){
            setErrorReport(previous => checkFilenamesLanguagesSanity)
            setModalSettings(previous => ({
                ...previous,
                processing: ''
            }))
            return
        }

        /* check alternatives */
        if((alternativeData.expected_sufix === '') || (alternativeData.final_sufix === '')){
            setErrorReport(previous => '"Expected Suffix" and "Final Suffix" must be filled')
            setModalSettings(previous => ({
                ...previous,
                processing: ''
            }))
            return

        }

        if(modalSettings.hardforceSubmit === false){

            let checkFilenamesIntoLanguageSanity = checkDoubleLanguages([data.tableLanguages[0]], data.tablePreset, dataGrid3, 'single', actorsData)
            if(checkFilenamesIntoLanguageSanity !== true){
                setErrorReport(previous => checkFilenamesIntoLanguageSanity)
                setModalSettings(previous => ({
                    ...previous,
                    processing: '',
                    hardforceSubmit: true
                }))
                return
            }
        }


        /* check if the filenames are duplicated in a single language */
        data['scriptId'] = modalSettings.selectedID;
        var ObjectToDB = JSON.stringify(data);
        const link = API_ADR('saveManageTargetFile=1')

        async function postData() {
            // send the username and password to the server
            // eslint-disable-next-line
            const response = await axios.post(
                    link,
                    data,
                    {headers}
            ).then(function (response) {
                props.onApplySettings()
            })
            .catch(function (error) {

            });
        }
        postData()
    }

    const created = () => {
        previewGrid.localeObj.currentLocale.EmptyRecord = 'No preview';
        previewGrid.keyConfigs.escape = '';
    }

    const seletedGrid = (selectedRow) => {

        let dataAltForGrids = alternativeData
        if(selectedRow !== undefined){
            dataAltForGrids = selectedRow
        }

        if(
            (selectedRowGrid1 !== undefined) && (selectedRowGrid2 !== undefined) &&
            (selectedRowGrid1.length !== 0 && selectedRowGrid2.length !== 0)){
                setDataGrid3(preview => elaboratedDataPreview(selectedRowGrid1[0], selectedRowGrid2[0], dataGridBackup, dataAltForGrids, actorsData))
                previewGrid.refresh()
        }
    }

    const getFiletypesData = () => {

        const filetypes = [];
        presetsGrid.dataSource.forEach((i) => {
            if(i.file_description) {
                const item = {
                    filetypeName: i.file_description,
                    expPattern: i.expected_pattern,
                    finalPattern: i.final_pattern,
                    extension: i.specs_extension,
                    specs_samplerate: i.specs_samplerate,
                    specs_bitdepth: i.specs_bitdepth,
                    specs_channels: i.specs_channels,
                    specs_lufs_ref: i.specs_lufs_ref,
                    specs_lufs_negative: i.specs_lufs_negative,
                    specs_lufs_positive: i.specs_lufs_positive,
                    specs_max_truepeak: i.specs_max_truepeak,
                    active: i.active
                }
                filetypes.push(item)
            }
        });

        return filetypes

    }

    const getLanguagesData = () => {

        const languages = [];
        languagesGrid.dataSource.forEach((i) => {
            const item = {
                langId: i.language_id,
                langCode1: i.language_code_1,
                langCode2: i.language_code_2
            }
            languages.push(item);
        })

        return languages;

    }

    const getAltsData = () => {

        const alts = {
            expSuffix: alternativeData.expected_sufix ? alternativeData.expected_sufix : '_ALT',
            finalSuffix: alternativeData.final_sufix ? alternativeData.final_sufix : '_ALT',
            padding: alternativeData.padding,
            createSubfolders: alternativeData.create_subfolders,
            insideMain: alternativeData.inside_main_structure,
            folderName: alternativeData.alts_foldername,
            useForExp: alternativeData.use_for_expected
        }

        return alts;

    }

    const savePresetDB = async(data) => {

        const headers = {'Content-Type': 'text/plain'};
        const ObjectToDB = JSON.stringify(data);
        const link = API_ADR('saveTargetSettingsPreset=1')

        await axios.post(
            link,
            ObjectToDB,
            {headers}
        ).then(function (response) {
            if(!response.data.response) {
                presetsList.current = response.data.presets;
                duplicatedPresetName();
                return;
            }
            if(data.active === 0 || !(response.data && response.data.presets && response.data.presetId)) { return }
            presetsList.current = response.data.presets;
            setSelectedPreset(response.data.presetId);
            setPresetLoaded(response.data.presetId);
            setEditPreset(false);
        })
        .catch(function (error) {});

    }

    const duplicatedPresetName = () => {
        const previousName = presetNameRef.current.value;
        presetNameRef.current.value = 'Duplicated name';
        setDupPresetName(true);
        setTimeout(() => {
            setDupPresetName(false);
            presetNameRef.current.value = previousName;
            presetNameRef.current.focus();
        }, 1500)
    }

    const savePreset = () => {

        const newPresetName = presetNameRef.current.value.trim();

        if(!newPresetName) {
            presetNameRef.current.focus();
            return
        }

        if(selectedPreset === '-2') { return }

        if(!saveConfirmation) {
            setSaveConfirmation(true);
            setTimeout(() => { setSaveConfirmation(false) }, 3000);
            return
        }

        const duplicated = presetsList.current.filter((i) => i.preset_name.toLowerCase() === newPresetName.toLowerCase() && parseInt(i.id) !== parseInt(selectedPreset));

        if(duplicated.length > 0) {
            duplicatedPresetName();
            setSaveConfirmation(false);
            return
        }

        const data = {
            presetId: -1,
            presetName: newPresetName,
            active: 1,
            clientId: clientId.current,
            filetypes: getFiletypesData(),
            alts: getAltsData(),
            languages: getLanguagesData(),
        }

        const selectedItem = presetsList.current.find((i) => parseInt(i.id) === parseInt(selectedPreset) && parseInt(i.id) > -1)
        if(selectedItem) { data.presetId = parseInt(selectedItem.id) }

        savePresetDB(data);
        setSaveConfirmation(false);

    }

    const deletePreset = () => {

        if(!deleteConfirmation) {
            setDeleteConfirmation(true);
            setTimeout(() => { setDeleteConfirmation(false) }, 3000);
            return
        }

        const itemToDelete = presetsList.current.find((i) => parseInt(i.id) === parseInt(selectedPreset))
        if(itemToDelete) {
            const data = {
                presetId: selectedPreset,
                presetName: itemToDelete.preset_name,
                clientId: clientId.current,
                active: 0,
            }
            savePresetDB(data);
            const index = presetsList.current.indexOf(itemToDelete);
            if (index !== -1) { presetsList.current.splice(index, 1) }
        }
        setSelectedPreset(-2);
        setPresetLoaded(-2);
        setEditPreset(false);
        setDeleteConfirmation(false);

    }

    const handleEditPreset = () => {
        setEditPreset(true);
    }

    const loadPreset = () => {
        const item = presetsList.current.find((i) => parseInt(i.id) === parseInt(selectedPreset));
        const filetypes = item.filetypes ? JSON.parse(item.filetypes) : [];
        const languages = item.languages ? JSON.parse(item.languages) : [];
        const alts = {
            alts_foldername: item.alts_foldername,
            create_subfolders: item.create_subfolders,
            expected_sufix: item.expected_sufix,
            final_sufix: item.final_sufix,
            inside_main_structure: item.inside_main_structure,
            padding: item.padding,
            use_for_expected: item.use_for_expected
        }

        const newData = filetypes.map((i, idx) => {
            const existingFiletype = !originalPreset.current['filetypes'] ? null :
                                    JSON.parse(originalPreset.current['filetypes']).find((f) => f.file_description === i.file_description)?.id;
            const id = isNaN(existingFiletype) ? generateRandomString(10) : existingFiletype;
            return {
                ...i,
                id: id,
                MyId: idx,
                overwrite_filename: '0',
                fromsource_filename: '0',
                active: i.active.toString()
            }
        })

        filetypesGridRef.current.updateDataSource(newData);
        languagesGridRef.current.updateDataSource(languages);
        setAlternativeData((p) => { return {...p, ...alts} })
        altsGridRef.current.updateDataSource(alts);
        setPresetLoaded(parseInt(item.id));
    }

    const resetOriginalSetting = () => {
        filetypesGridRef.current.updateDataSource(JSON.parse(originalPreset.current['filetypes']));
        languagesGridRef.current.updateDataSource(JSON.parse(originalPreset.current['languages']));
        setAlternativeData(JSON.parse(originalPreset.current['alts']))
        altsGridRef.current.updateDataSource(JSON.parse(originalPreset.current['alts']));
        setPresetLoaded(-2);
    }

    useEffect(() => {
        if(presetNameRef.current) {
            const presetItem = presetsList.current.find((i) => parseInt(i.id) === parseInt(selectedPreset))
            presetNameRef.current.value = selectedPreset === '-1' || !presetItem ? '' : presetItem.preset_name;
            presetNameRef.current.focus();
        }
    },[editPreset, selectedPreset])

    const saveDeleteButton = (type) => {

        const outlineStyle = (type === 'Load' && presetLoaded !== parseInt(selectedPreset)) ||
                             (type === 'Save' && saveConfirmation) ||
                             (type === 'Delete' && deleteConfirmation)
                            ? '3px solid #4ccbff' : '';

        const style = {
            borderRadius: '4px',
            display: 'grid',
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6px',
            outline: outlineStyle,
            outlineOffset: '-3px'
        }
        const action = type==='Save' ? savePreset : type==='Delete' ? deletePreset : type==='Edit' ? handleEditPreset : loadPreset;
        const className = type === 'Save' ? 'btn btn-outline-primary' : type === 'Delete' ? 'btn btn-outline-danger' : 'btn btn-outline-secondary';

        return (
            <button type='button' className={className} style={style} onClick={action} title={type + ' preset'}>
                {type === 'Save' &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy" viewBox="0 0 16 16">
                        <path d="M11 2H9v3h2z"/>
                        <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
                    </svg>
                }
                {type === 'Delete' &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                    </svg>
                }
                {type === 'Edit' &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                    </svg>
                }
                {type === 'Load' &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1z"/>
                        <path fillRule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                    </svg>
                }
            </button>
        )
    }

    const handlePresetChange = (e) => {
        setEditPreset(e.target.value === '-1')
        setSelectedPreset(e.target.value);
    }

    const lufsHandler = (e) => {
        lufsPanelRef.current.openModal(e.rowData)
    }

    const applyLufsSettings = (props) => { 
        filetypesGridRef.current.addLufsInfo(props)  
    }

    if(modalSettings.showModal === false){return null}
    if(modalSettings.isLoading === true){return <SpinnerCentered />}

    return (<>

        <div className="modal fade show" id="AudioMateMenu" tabIndex="-1" aria-modal="true">
            <div className="modal-dialog modal-xl modal-dialog-centered" >
                <LUFSPanel ref={lufsPanelRef} onApplyLufsSettings={applyLufsSettings} />
                <div className="modal-content" id="AudioMateWindowMenu">
                    <div className="modal-header" style={{padding: '7px 10px 5px 16px'}}>

                    <div className="centered-badge">
                    {errorReport !== '' && <span className="badge bg-danger" style={{display: 'block'}}>{errorReport}</span>}
                    </div>


                       <SettingsInputCompositeIcon style={{fontSize: '16px'}} /> <h6 style={{marginLeft: '8px'}} className="modal-title">
                        Target Files Settings - Script <b>{modalSettings?.info?.script_name} ({modalSettings?.info?.languages})</b>
                        {modalSettings.info?.uploadReference && 
                            <Link 
                                title="Open Upload page for Target" 
                                to={`/uploads/target-${modalSettings.info.uploadReference.project_id}-${modalSettings.info.uploadReference.batch_id}-${modalSettings.info.uploadReference.script_id}`}
                                style={{marginLeft: '10px'}}>
                                    <i className="bi bi-cloud-upload-fill" style={{color: 'blue'}}></i>
                            </Link>
                        }
                        
                        {/*<button title={disabled ? disabledTitle : "Open Upload page for the Target"} className="btn btn-sm  btn-outline-primary shadow-none" disabled={disabled} style={positionLink}
                        onClick={() => goto(`/uploads/target-${project_id}-${batch_id}-${script_id}`)}>
                        <i className="bi bi-cloud-upload-fill"></i>
                    </button>}*/}
                        </h6>


                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                    </div>
                    {/*<SpinnerCentered />*/}
                    <div className="modal-body">

                        <div className="manageTarget">
                            <div className="row">
                                <div style={{display: 'inline-block', width: '420px'}}>
                                    <LanguagesGrid ref={languagesGridRef} propGrid1={dataGrid1} onSeletedGrids={seletedGrid} />
                                </div>
                                <div style={{display: 'inline-block', width: '710px'}}>
                                    <AlternativeModule ref={altsGridRef} altInfo={alternativeData} updateAltData={updateAltData} />
                                </div>
                            </div>
                            <PresetsGrid ref={filetypesGridRef} propGrid2={dataGrid2} propsSource={sourceFileTypes.current} onSeletedGrids={seletedGrid} revertToOriginal={resetOriginalSetting} lufsHandler={lufsHandler} />

                            <div style={{position: 'relative', height: '300px', marginTop: '6px'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <h5 style={{margin: 'auto 0'}}>Preview results</h5>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        {(saveConfirmation || deleteConfirmation) && <span style={{fontSize: '0.65em', alignSelf: 'end', color: '#F00'}}>Press the button again to confirm</span>}
                                        <div style={{display: 'flex', padding: '4px 0', gap: '4px', alignItems: 'baseline', margin: 'auto 0', overflow: selectedPreset > -2 ? 'auto' : ''}}>
                                            Presets:
                                            <select ref={presetsSelectRef} className="form-select form-select-sm h-100"
                                                    style={{minWidth: '160px', maxHeight: '31px'}}
                                                    onChange={handlePresetChange}
                                                    value={selectedPreset}>
                                                <option key='select' value={-2}>Select a preset</option>
                                                <option key='new' value={-1}>New</option>
                                                {presetsList.current &&
                                                presetsList.current.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id}>{item.preset_name}</option>
                                                    )
                                                })}
                                            </select>
                                            {!editPreset && selectedPreset > -1 &&
                                                <div style={{display: 'flex', gap: '2px', margin: 'auto'}}>
                                                    {saveDeleteButton('Load')}
                                                    {saveDeleteButton('Edit')}
                                                </div>
                                            }
                                            {editPreset &&
                                                <div style={{display: 'flex', gap: '2px', margin: 'auto'}}>
                                                    <input ref={presetNameRef} style={{minWidth: '160px', marginRight: '2px', color: dupPresetName ? '#F00' : ''}} type="text"className="form-control form-control-sm" placeholder="Preset name"/>
                                                    {selectedPreset > -1 && saveDeleteButton('Load')}
                                                    {saveDeleteButton('Save')}
                                                    {selectedPreset > -1 && saveDeleteButton('Delete')}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <GridComponent
                                    dataSource={dataGrid3}
                                    ref={grid => previewGrid = grid}
                                    gridLines='Both'
                                    height="100%"
                                    allowTextWrap={true}
                                    allowResizing={true}
                                    width="auto"
                                    created={created}
                                    pageSettings={{ pageSize: 100 }}
                                    allowPaging={true}
                                    >
                                    <ColumnsDirective>
                                        {/*<ColumnDirective field='id' headerText="Line Id" width='90px'/>*/}
                                        <ColumnDirective field='script_order' headerText="Script Order" width='90px' textAlign="center"/>
                                        {/*<ColumnDirective field='pm_notes' headerText="PM notes" width='200px'/>*/}
                                        <ColumnDirective field='character_name' headerText="Character" width='130px'/>
                                        <ColumnDirective field='actor_name' headerText="Actor" width='130px'/>
                                        <ColumnDirective field='filename_path' headerText="Filename Path" width='200px'/>
                                        <ColumnDirective field='filename' headerText="Filename" width='200px'/>
                                        <ColumnDirective field='filename2' headerText="Filename 2" width='200px'/>
                                        <ColumnDirective field='expected_pattern' headerText="Expected Filename" width='300px'/>
                                        <ColumnDirective field='final_pattern' headerText="Target Filename Sample" width='300px'/>
                                    </ColumnsDirective>
                                    <Inject services={[Resize, Page]}/>
                                </GridComponent>
                            </div>
                        </div>
                    </div>
                    <div className="text-center" style={{margin: '13px'}}>
                        <button className="btn btn-sm btn-primary mt-3"
                            onClick={saveSettings}
                            disabled={modalSettings.processing !== '' ? true : false}
                            >Apply Settings {modalSettings.processing}</button>
                        {modalSettings.hardforceSubmit === true &&
                        <span
                            style={{position: 'absolute', color: 'red', 'marginTop': '21px', 'marginLeft': '10px', 'fontWeight': 'bold', 'fontSize': '13px'}}
                            > (You can force the operation if it's a known problem) </span>}
                    </div>
                </div>
            </div>
        </div>
        </>
    )

})






const LanguagesGrid = forwardRef((props, ref) => {


    const [subDataGrid1, setSubDataGrid1] = useState([])

    useEffect(() => {
        setSubDataGrid1(props.propGrid1)
        window.removeEventListener('keydown', keyboardControlKeyDownGrid1);
        window.addEventListener('keydown', keyboardControlKeyDownGrid1);

        return () => {
            //setSubDataGrid1([])
            window.removeEventListener('keydown', keyboardControlKeyDownGrid1);
        }


    }, [props.propGrid1])

    function keyboardControlKeyDownGrid1(e) {

        let tableInEdition = document.querySelectorAll('.e-gridform.e-lib.e-formvalidator').length

        if(tableInEdition === 1){
            if (e.keyCode === 13) {
                e.preventDefault();
                languagesGrid.closeEdit()
            }
        }
    }


    const languageGridEditOption = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch', showConfirmDialog: false };

    /* update saved data */
    const Saved = (args) => {
        const newVal = args.value.replace(/[/\\:*?"<>|\t\n\r]/g, '')
        const updatedData = subDataGrid1.map(item => {
            if (item.MyId === args.rowData.MyId) {
                return { ...item, [args.columnName]: newVal };
            }
            return item;
        });


        //selectedRowGrid1 = languagesGrid.getSelectedRecords();

        setSubDataGrid1(updatedData)
        //languagesGrid.refresh();
    }

    /* select row */
    const rowSelected = (args) => {
        selectedRowGrid1 = languagesGrid.getSelectedRecords();
        props.onSeletedGrids()
    }

    const actioncomplete = (args) => {
        if(args.requestType === 'refresh'){
            try{
                if (selectedRowGrid1 !== undefined) {
                    languagesGrid.selectRow(selectedRowGrid1[0].MyId)
                }
            } catch(e){}
        }
    }

    const created = () => {
        languagesGrid.keyConfigs.enter = '';
    }

    useImperativeHandle(ref, () => ({
        updateDataSource: (newData) => {
            setSubDataGrid1((p) =>
                p.map((l) => {
                    const newLangData = newData.find((lg) => parseInt(lg.language_id) === parseInt(l.language_id))
                    return !newLangData ? l : {...l, language_code_1: newLangData.language_code_1, language_code_2: newLangData.language_code_2}
                })
            )
        }
    }))

    return (
        <div style={{width: '400px'}}>
            <GridComponent
                dataSource={subDataGrid1}
                ref={grid => languagesGrid = grid}
                selectedRowIndex={0}
                gridLines='Both'
                editSettings={languageGridEditOption}
                //cellEdit={cellEdit}
                cellSaved={Saved}
                rowSelected={rowSelected}
                height={'110px'}
                actionComplete={actioncomplete}
                selectionSettings={{enableToggle: false}}
                created={created}
                dataBound={() => languagesGrid.selectRow(0)}
                >
                <ColumnsDirective>
                    <ColumnDirective field='language_code' headerText="Language" width='100' allowEditing={false} textAlign="Center" />
                    <ColumnDirective field='language_code_1' headerText="Language Code 1" width='100' textAlign="Center"/>
                    <ColumnDirective field='language_code_2' headerText="Language Code 2" width='100' textAlign="Center"/>
                </ColumnsDirective>
                <Inject services={[Edit, Selection]}/>
            </GridComponent>
        </div>
    )
})


const PresetsGrid = forwardRef((props, ref) => {

    const showHideSpecs = useRef(takePermissionLocalStorage())

    const tags = ['LangCode1','LangCode2','Character', 'Actor','Path','Filename','Filename2','LineId','Order'];
    //const [subDataGrid2, setSubDataGrid2] = useState([])
    const subDataGrid2 = useRef(props.propGrid2);
    const [showPreview, setShowPreview] = useState(false)
    const [confirmState, setConfirmState] = useState(false);
    const gridKeyboardShortcuts = useRef({});
    const editingCellArgs = useRef(null);
    const previousValueSelect = useRef(null);
    let clickCursorPosition = {first: 0, second: 0};

    const audioSpecs = {
        'specs_samplerate': ['', '8000', '11025', '16000', '22050', '32000', '44100', '48000', '88200', '96000'],
        'specs_bitdepth': [null, 'Linear', '16', '24', '32', 'Compr.', '48', '64', '80', '96', '112', '128', '160', '192', '224', '256', '320'],
        'specs_channels': ['1', '2', '3', '4', '5', '6', '7', '8'],
        'specs_extension': allowedFileFormats(),
        'source_filetype_id': props.propsSource
    };


    useEffect(() => {
        //subDataGrid2.current = props.propGrid2
        //setSubDataGrid2(props.propGrid2)
        presetsGrid.keyConfigs.escape = '';
        window.removeEventListener('keydown', keyboardControlKeyDownGrid2);
        window.addEventListener('keydown', keyboardControlKeyDownGrid2);

        return () => {
            //setSubDataGrid2([])
            window.removeEventListener('keydown', keyboardControlKeyDownGrid2);
        }
    }, [props.propGrid2])

    function keyboardControlKeyDownGrid2(e) {

        let tableInEdition = document.querySelector('.e-gridform.e-lib.e-formvalidator')

        if(tableInEdition){
            if (e.keyCode === 13) {
                e.preventDefault();

                let rowCell = tableInEdition.closest('.e-rowcell');

                if (rowCell) {
                    // Esegui le azioni necessarie
                    rowCell.previousElementSibling.click();
                }

                const element = document.querySelector('.buttontags');
                element.style.display = 'none';
            }
        }
    }

    const presetGridEditOption = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch', showConfirmDialog: false };

       /* update saved data */
       const Saved = (args) => {

        const updatedData = subDataGrid2.current.map(item => {
            if (item.MyId === args.rowData.MyId) {
                let value = args.value
                if((args.columnName === 'expected_pattern') || (args.columnName === 'final_pattern')){
                    value = value.replace(/\\/g, '/').replace(/\/{2,}/g, '/');
                    value = path.normalize(value)
                }
                if(args.columnName === "specs_extension"){
                    // replace all dots
                    if(value.length > 0){
                        value = `.${value.replace(/\./g, '')}`;
                    }
                }
                const element = document.querySelector('.buttontags');
                element.style.display = 'inline';
                return { ...item, [args.columnName]: value };
            }

            const element = document.querySelector('.buttontags');
            element.style.display = 'inline';
            return item;
        });

        subDataGrid2.current = updatedData;
        presetsGrid.setProperties({dataSource: subDataGrid2.current})
        /*setSubDataGrid2(previous => {
            return updatedData
        })*/
        //languagesGrid.refresh();
    }

    const actioncomplete = (args) => {

        /*if(args.requestType === 'refresh'){

            let includesOrNot = false
            // find if expected and final contain a dot and remove it //
            let tempTable = presetsGrid.dataSource.map(item => {
                let expected = item.expected_pattern;
                let final = item.final_pattern;
                if(expected !== undefined){
                    if(expected.includes('.')){
                        includesOrNot = true
                        expected = expected.replace(/\./g, '/');
                    }
                }
                if(final !== undefined){
                    if(final.includes('.')){
                        includesOrNot = true
                        final = final.replace(/\\/g, '/');
                    }
                }
                return {...item, expected_pattern: expected, final_pattern: final}
            })

            if(includesOrNot === true){
                presetsGrid.dataSource = tempTable
            }
        }*/
    }

    /* select row */
    const rowSelected = (args) => {
        if(!presetsGrid) { return }
        selectedRowGrid2 = presetsGrid.getSelectedRecords();
        props.onSeletedGrids(selectedRowGrid2.MyId)
    }

    /* template for active column */

    const handleActive = (props) => {
        subDataGrid2.current[props.MyId].active = props.active === '1' ? '0' : '1';
        presetsGrid.setProperties({dataSource: subDataGrid2.current})
        /*const updatedData = subDataGrid2.current.map(item => {
            if (item.MyId === props.MyId) {
                return { ...item, active: props.active === '1' ? '0' : '1' };
            }
            return item;
        });
        subDataGrid2.current = updatedData;
        /*setSubDataGrid2(previous => {
            return updatedData
        })*/
    }


    /* show buttons for tags */
    const beginEdit = (args) => {
        if(args.name === "cellEdit"){
            if(args.columnName === 'expected_pattern' || args.columnName === 'final_pattern'){
                const element = document.querySelector('.buttontags');
                element.style.display = 'inline';
            }
        }
    }

    const showTagsPanel = (field) => {
        if(field === 'expected_pattern' || field === 'final_pattern' || field === 'close'){
            const element = document.querySelector('.buttontags');
            element.style.display = field !== 'close' ? 'inline' : 'none';
        }
    }

    /* insert tags */
    function insertAdaptTag(tagToInsert) {

        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const startPos = range.startOffset;
        const endPos = range.endOffset;
        const c = editingCellArgs.current;
        const textBefore = c.cell.textContent.substring(0, startPos);
        const textAfter = c.cell.textContent.substring(endPos, c.cell.textContent.length);
        c.cell.textContent = textBefore + tagToInsert + textAfter;
        range.setStart(c.cell.firstChild, startPos + tagToInsert.length);
        range.setEnd(c.cell.firstChild, startPos + tagToInsert.length);
        selection.removeAllRanges();
        selection.addRange(range);

    }

    /* add new preset */
    const addNewPreset = () => {
        let newPreset = {
            id: generateRandomString(10),
            MyId: subDataGrid2.current.length,
            active: '1',
            file_description: '',
            expected_pattern: '',
            final_pattern: '',
            specs_extension: '',
            overwrite_filename: '0',
            fromsource_filename: '0',
            specs_lufs_ref: null,
            specs_lufs_negative: null,
            specs_lufs_positive: null,
            specs_max_truepeak: null,
            source_filetype_id: '0',
        }

        let updatedData = [...subDataGrid2.current, newPreset]
        subDataGrid2.current = JSON.parse(JSON.stringify(updatedData));
        presetsGrid.setProperties({dataSource: subDataGrid2.current});
        setTimeout(() => {
            presetsGrid.selectRow(subDataGrid2.current.length - 1);
            presetsGrid.getContent().children[0].scrollTop = 10000
        }, 100);
        /*setSubDataGrid2(previous => {
            return updatedData
        })*/
        //setTimeout(() => {
            /* find the last td inside the container .presetcontainer */
            //let lastTd = document.querySelector('.presetcontainer .e-grid .e-gridcontent .e-content .e-table .e-row:last-child .e-rowcell:last-child')
            //lastTd.click()
            //presetsGrid.selectRow(newPreset.MyId)
        //}, 100)
    }

    /* remove enter key action */
    const created = () => {
        presetsGrid.keyConfigs.enter = '';
        presetsGrid.localeObj.localeStrings.EmptyRecord = 'No presets found';
    }

    /************************************************************** */
    /* switch template */
    function switchTemplate(props) {
        return (
            <div className="form-check form-switch" style={{marginLeft:'8px'}}>
                <input style={{width: '42px', height: '19px', cursor: 'pointer'}}
                className="form-check-input"
                type="checkbox" defaultChecked={props.active === '1' ? true : false} onChange={() => handleActive(props)}/>
            </div>
        )
    }

    function deletionTemplate(props) {
        if(isNaN(props.id)){
            let style = {cursor: 'pointer', marginLeft: '18px'}
        return <span className="e-btn-icon e-icons e-delete" title="Delete" style={style} onClick={() => deleteRow(props)}></span>
        }
    }

    const deleteRow = (props) => {
        let updatedData = subDataGrid2.current.filter(item => item.MyId !== props.MyId)
        subDataGrid2.current = JSON.parse(JSON.stringify(updatedData))
        presetsGrid.setProperties({dataSource: subDataGrid2.current})
        if(subDataGrid2.current.length===0) { return }
        setTimeout(() => {
            presetsGrid.selectRow(0);
            presetsGrid.getContent().children[0].scrollTop = 0
        }, 100);
    }

    const handleCheckboxChange = (props) => {

        const updatedData = subDataGrid2.current.map(item => {

            if (item.MyId === props.MyId) {
                return { ...item, [props.columnName]: props.checked === true ? '1' : '0' };
            }
            return item;
        })
    }
    /********************************************************************************************* */


    const selectionSettings = {enableToggle: false}

    const dataBoundPresets = () => {
        if(!presetsGrid) { return }
        try{
            presetsGrid.selectRow(selectedRowGrid2[0].MyId)
        }
        catch(e){
            presetsGrid.selectRow(0)
        }
    }

    const exitEditMode = () => {
        
        const cell = editingCellArgs.current.cell;
        let origChild = editingCellArgs.current.originalChild
        editingCellArgs.current = null;
        cell.contentEditable=false;     
        cell.removeEventListener('focusout', cellOnBlur);
        cell.removeEventListener('keydown', keyboardEditEvent);
        cell.removeEventListener('paste', handlePaste);
        for (let key of Object.keys(gridKeyboardShortcuts.current)) {
            presetsGrid.keyConfigs[key] = gridKeyboardShortcuts.current[key];
        }
        cell.classList.remove("cellediting");
        if (origChild) {
            if(cell.childNodes.length > 0) { cell.removeChild(cell.childNodes[0]) };
            cell.appendChild(origChild);
        }
        showTagsPanel('close');
    }

    const cellOnBlur = (e) => {



        if(e.relatedTarget.id.includes('tagButton')) { return }

        const c = editingCellArgs.current;
        if (!c || c.cell.contains(e.relatedTarget)) { return }
        const field = c.column.field;
        let newVal = null;
        /* check if exists a menu select  */
        const formValues = document.querySelector('#specs-data-form')
        if(formValues){
            newVal = ['Linear', 'Compr.'].includes(formValues.value) ? null : formValues.value
        } else {
            //if(field === 'specs_extension') {
                newVal = '.' + c.cell.textContent.replace(/\./g, '').replace(/[/\\:*?"<>|\t\n\r]/g, '')
            //} else {
                newVal = c.cell.textContent.trim().replace(/[:*?"|\t\n\r]/g, '');
                tags.forEach((t) => {
                    const regex = new RegExp(`<${t}>`, 'g');
                    newVal = newVal.replace(regex, '*' + t + '?')
                })
                newVal = newVal.replace(/[<>]/g, '').replace(/\*/g, '<').replace(/\?/g, '>').replace(/\\/g, '/').replace(/\.+\//g, '/').replace(/\/{2,}/g, '/');
            //}
        }

       
        c.cell.textContent = newVal === 'null' ? '' : newVal 

        if(previousValueSelect.current !== null){// force recompute if different
            if(previousValueSelect.current !== newVal){
                c.rowData[field] = "---------------------------"
            }
            previousValueSelect.current = null
        }

        if(c.rowData[field] !== newVal) {
            c.rowData[field] = newVal === 'null' ? '' : newVal ;
            presetsGrid.setProperties({dataSource: subDataGrid2.current})
        }
        exitEditMode();

    }

    const moveCursorToPosition = () => {
        const c = editingCellArgs.current;
        const selection = window.getSelection();
        const range = document.createRange();
        const element = c.cell.firstChild ? c.cell.firstChild : c.cell;
        const position = element.length < c.startPos ? element.length : c.startPos;
        range.setStart(element, position);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
    }

    const handlePaste = (e) => {
        e.preventDefault();

        
        const text = e.clipboardData.getData('text/plain');
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const startPos = range.startOffset;
        const endPos = range.endOffset;
        const c = editingCellArgs.current;
        const textBefore = c.cell.textContent.substring(0, startPos);
        const textAfter = c.cell.textContent.substring(endPos, c.cell.textContent.length);
        c.cell.textContent = textBefore + text + textAfter;
        range.setStart(c.cell.firstChild, startPos);
        range.setEnd(c.cell.firstChild, startPos + text.length);
        selection.removeAllRanges();
        selection.addRange(range);
    }

    const keyboardEditEvent = (e) => {



        if(!editingCellArgs.current) { return }
        const c = editingCellArgs.current;

        if(e.keyCode === 'ArrowLeft') {
            e.preventDefault();
        } else if(e.key === 'Enter'){
            e.preventDefault();
            const field = c.column.field;
            let newVal = null;
            if(field === 'specs_extension') {
                newVal = '.' + c.cell.textContent.replace(/\./g, '').replace(/[/\\:*?"<>|\t\n\r]/g, '')
            } else {
                newVal = c.cell.textContent.trim().replace(/[:*?"|\t\n\r]/g, '');
                tags.forEach((t) => {
                    const regex = new RegExp(`<${t}>`, 'g');
                    newVal = newVal.replace(regex, '*' + t + '?')
                })
                newVal = newVal.replace(/[<>]/g, '').replace(/\*/g, '<').replace(/\?/g, '>').replace(/\\/g, '/').replace(/\.+\//g, '/').replace(/\/{2,}/g, '/');
            }
            if(c.rowData[field] !== newVal) {
                c.rowData[field] = newVal;
                presetsGrid.setProperties({dataSource: subDataGrid2.current})
            }
            exitEditMode();
        } else if(e.key === 'Escape'){
            e.preventDefault();
            exitEditMode();
        }

    }

    const handleCellClick = (c) => {
        //if(editingCellArgs.current || !c.cell.classList.contains(pronCntxt.liveTask.current + 'editable')) { return }
        if(editingCellArgs.current) { return }
        const field = c.column.field;
        const value = c.rowData[field];
        if(!value) {
            clickCursorPosition = { first: clickCursorPosition.second, second: 0 }
            return
        }
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        let pos = range.startOffset;
        const findNode = (nodes) => {
            for (let i = 0; i < nodes.length; i++) {
                if(nodes[i] === selection.anchorNode) { return true }
                if(nodes[i].nodeName === '#text') { pos += nodes[i].length };
                if(nodes[i].hasChildNodes()) {
                    if(findNode(nodes[i].childNodes)) { return }
                };
            }
            return false;
        }
        findNode(c.cell.childNodes);

        clickCursorPosition = { first: clickCursorPosition.second, second: pos };
    }

    const handleCellDoubleClick = (c) => {



        if(!c.column.allowEditing || editingCellArgs.current) { return }
        const field = c.column.field;

        if(field === 'specs_lufs') { 
            /* open lufs modal */
            props.lufsHandler(c)
            return 
        }

        const formsWithOptionSelection = ['specs_samplerate', 'specs_bitdepth', 'specs_channels', /*'specs_extension',*/ 'source_filetype_id']

        if(formsWithOptionSelection.includes(field)){

            previousValueSelect.current = c.rowData[field];

            try {
                const cell = c.cell;
                
                const valuesOptions = audioSpecs[field];
                const selectElement = document.createElement('select');
                // Populate the select element with options
                valuesOptions.forEach((option) => {
                  const optionElement = document.createElement('option');
                  optionElement.value = field === 'source_filetype_id' ? option.id : option;
                  optionElement.textContent = field === 'source_filetype_id' ? option.file_description : option;;
                  selectElement.appendChild(optionElement);
                });

                selectElement.id = 'specs-data-form';
                selectElement.value = c.rowData[field];
                selectElement.addEventListener('change', (event) => {
                  c.rowData[field] = event.target.value; // Update data on change
                });
        
                // Save current state of the cell before editing
                c['originalChild'] = cell.removeChild(cell.childNodes[0]);
        
                // Replace the cell content with the dropdown
                cell.appendChild(selectElement);
                editingCellArgs.current = c;
        
                // Event listeners and keyboard navigation handling
                gridKeyboardShortcuts.current = { ...presetsGrid.keyConfigs };
                for (let key of Object.keys(presetsGrid.keyConfigs)) {
                  presetsGrid.keyConfigs[key] = '';
                }
        
                cell.removeEventListener('focusout', cellOnBlur);
                cell.addEventListener('focusout', cellOnBlur);
              } catch (error) {
                exitEditMode();
              }
              return 
        } 


        try {
            c['startPos'] = clickCursorPosition.first;

            c['originalChild'] = c.cell.removeChild(c.cell.childNodes[0]);
            editingCellArgs.current = c;
            gridKeyboardShortcuts.current = {...presetsGrid.keyConfigs};
            for (let key of Object.keys(presetsGrid.keyConfigs)) {
                presetsGrid.keyConfigs[key] = '';
            }
            c.cell.removeEventListener('focusout', cellOnBlur);
            c.cell.removeEventListener('keydown', keyboardEditEvent);
            c.cell.removeEventListener('paste', handlePaste);
            c.cell.addEventListener('keydown', keyboardEditEvent);
            c.cell.addEventListener('paste', handlePaste);
            c.cell.addEventListener('focusout', cellOnBlur);
            c.cell.classList.add("cellediting");
            c.cell.contentEditable=true;
            c.cell.spellcheck = false;
            c.cell.textContent = c.rowData[field];
            moveCursorToPosition();
            showTagsPanel(field);
        } catch (error) {
            exitEditMode();
        }
    }

    const SelectTemplate = (data) => {
        const field = data.column.field;
        const selection = data[field]

        return <select value={selection}>
            {audioSpecs[field].map(el => {
                return <option value={el}>{el}</option>
            })}
            </select>
        //QUI
    }

    function NormalTemplate(data) {
        const field = data.column.field;

        if(field === 'specs_lufs'){          
            const specMode = data.specs_lufs_ref
            const truePeak = data.specs_max_truepeak !== null ? `TP.: ${data.specs_max_truepeak}dB` : null
            
            //return ("MODE"+ specMode+ "|NEG" + data.specs_lufs_negative + "|POS" + data.specs_lufs_positive)

            if(specMode === null) {                
                    return <>----<br/>{truePeak}</>
            }
            if(specMode > 0){
                let mode = '';
                let info = ''
                if(specMode === 1) { mode = 'Source'; }
                if(specMode === 2) { mode = 'Column';}
                if(specMode === 3) { mode = 'Fixed';}
                if(specMode < 3){
                    info = `Toll. ${data.specs_lufs_negative}/${data.specs_lufs_positive}dB`;
                }
                return <>[{mode}] {info}<br/>{truePeak}</>
            } else {
                const info = `Toll. ${(specMode + data.specs_lufs_negative).toFixed(1)}/${(specMode + data.specs_lufs_positive).toFixed(1)}dB`;
                return <>[{`Fixed ${specMode}dB`}]<br/>({info})<br/>{truePeak}</> //specMode

            }  
        } else if(field === 'source_filetype_id') {

            const text = audioSpecs.source_filetype_id.find(el => el.id === data[field])?.file_description

            if(text){
                return <>---</>
            } else {
                return <>{text}</>
            }

            // check with Dani

            /*if(data[field] === null) return <>----</>

            return <>{data[field] && audioSpecs.source_filetype_id.find(el => el.id === data[field]).file_description}</>*/
        }

        return (
            <div>
                {data[field]}
            </div>
        );
    };

    useImperativeHandle(ref, () => ({
        updateDataSource: (newData) => {
            subDataGrid2.current = newData
            presetsGrid.setProperties({dataSource: subDataGrid2.current})
        },
        addLufsInfo: (newData) => {

            let updatedData = presetsGrid.dataSource[newData.MyId]

            updatedData.specs_lufs_ref = newData.specs_lufs_ref === null ? null : newData.specs_lufs_ref === 3  ? newData.fixedValue : newData.specs_lufs_ref
            updatedData.specs_lufs_negative = newData.specs_lufs_ref === null ? null : newData.specs_lufs_negative
            updatedData.specs_lufs_positive = newData.specs_lufs_ref === null ? null : newData.specs_lufs_positive 
            updatedData.specs_max_truepeak = newData.specs_max_truepeak
            presetsGrid.setRowData(newData.MyId, updatedData);
        }

    }))

    const revertToOriginal = () => {
        setConfirmState((p) => {
            if(p) {
                props.revertToOriginal()
            } else {
                setTimeout(() => { setConfirmState(false) }, 3000);
            }
            return !p
        });
    }


    return (
        <div style={{marginTop: '10px'}} className="presetcontainer">
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <button className="btn btn-sm btn-primary" style={{marginBottom: '4px', height: '25px', padding: '0px 5px'}} onClick={addNewPreset}>Add new FileType</button>
                    <div className="buttontags" style={{display: 'none', marginLeft: '40px', marginBottom: '4px'}}>
                        <button id='tagButtonLang1' type="button" className="btn btn-outline-primary btn-sm" onClick={() => insertAdaptTag('<LangCode1>')}>Lang Code 1</button>
                        <button id='tagButtonLang2' type="button" className="btn btn-outline-primary btn-sm" onClick={() => insertAdaptTag('<LangCode2>')}>Lang Code 2</button>
                        <button id='tagButtonChar' type="button" className="btn btn-outline-primary btn-sm" onClick={() => insertAdaptTag('<Character>')}>Character</button>
                        <button id='tagButtonAct' type="button" className="btn btn-outline-primary btn-sm" onClick={() => insertAdaptTag('<Actor>')}>Actor</button>
                        <button id='tagButtonPath' type="button" className="btn btn-outline-primary btn-sm" onClick={() => insertAdaptTag('<Path>')}>Path</button>
                        <button id='tagButtonFn' type="button" className="btn btn-outline-primary btn-sm" onClick={() => insertAdaptTag('<Filename>')}>Filename</button>
                        <button id='tagButtonFn2' type="button" className="btn btn-outline-primary btn-sm" onClick={() => insertAdaptTag('<Filename2>')}>Filename 2</button>
                        {/*<button id='tagButtonLineId' type="button" className="btn btn-outline-primary btn-sm" onClick={() => insertAdaptTag('<LineId>')}>Line Id</button>*/}
                        <button id='tagButtonOrder' type="button" className="btn btn-outline-primary btn-sm" onClick={() => insertAdaptTag('<Order>')}>Script Order</button>
                    </div>
                </div>
                <div style={{display: 'flex', gap: '4px', alignItems: 'center', paddingBottom: '4px'}}>
                    <span style={{fontSize: '0.7em', color: confirmState ? '#F00' : ''}}>{confirmState ? 'Click again to confirm' : 'Discard all changes'}</span>
                    <button className="btn btn-sm btn-outline-secondary"
                            style={{height: '25px', padding: '0px 5px', outlineOffset: '-2px', outline: confirmState ? '3px solid #4ccbff' : ''}}
                            onClick={revertToOriginal}
                            title='Revert to original settings'>
                        <i className="bi bi-arrow-clockwise"></i>
                    </button>
                </div>
            </div>
            <div className='presetDivGrid' style={{height: '130px'}}>
            
            <GridComponent id='targetsettingsgrid'
                dataSource={subDataGrid2.current}
                ref={grid => presetsGrid = grid}
                selectedRowIndex={0}
                gridLines='Both'
                allowResizing={true}
                allowTextWrap={true}
                //editSettings={presetGridEditOption}
                recordClick={handleCellClick}
                recordDoubleClick={handleCellDoubleClick}
                //allowEditing={true}
                rowSelected={rowSelected}
                height={'100%'}
                selectionSettings={selectionSettings}
                actionComplete={actioncomplete}
                created={created}
                cellSaved={Saved}
                cellEdit={beginEdit}
                dataBound={dataBoundPresets}
                >
                <ColumnsDirective>
                    <ColumnDirective field='MyId' headerText='' width='0' isPrimaryKey={true} allowEditing={false}/>
                    <ColumnDirective field='active' headerText='Active' template={switchTemplate} disableHtmlEncode={false} width='80px' textAlign="Center" allowResizing={false} allowEditing={false}/>
                    <ColumnDirective field='file_description' headerText="FileType" width='100px' template={NormalTemplate} allowEditing={true}/>
                    <ColumnDirective field='expected_pattern' headerText="Expected filename pattern" width='300px' template={NormalTemplate} allowEditing={true}/>
                    <ColumnDirective field='final_pattern' headerText="Final filename pattern" width='300px' template={NormalTemplate} allowEditing={true}/>
                    <ColumnDirective field='specs_extension' headerText="Ext." width='80px' template={NormalTemplate} allowEditing={true} textAlign="Center"/>
                    <ColumnDirective field='specs_samplerate' headerText="SampleRate" width='80px' template={NormalTemplate} allowEditing={true} textAlign="Center" visible={showHideSpecs.current.UITemp ?? true}/>
                    <ColumnDirective field='specs_bitdepth' headerText="Bit" width='80px' template={NormalTemplate} allowEditing={true} textAlign="Center" visible={showHideSpecs.current.UITemp ?? true}/>
                    <ColumnDirective field='specs_channels' headerText="Channels" width='80px' template={NormalTemplate} allowEditing={true} textAlign="Center" visible={showHideSpecs.current.UITemp ?? true}/>
                    <ColumnDirective field='specs_lufs' headerText="Audio Levels" width='180px' template={NormalTemplate} allowEditing={true} textAlign="Center" visible={showHideSpecs.current.UITemp ?? true}/>
                    <ColumnDirective field='source_filetype_id' headerText="Source Filetype" width='180px' template={NormalTemplate} allowEditing={true} textAlign="Center" visible={showHideSpecs.current.UITemp ?? true}/>
                    <ColumnDirective template={deletionTemplate} headerText='' width='80px' allowEditing={false}/>
                    {/*<ColumnDirective field='overwrite_filename' headerText="Overwrite FN" width='100'/>
                    <ColumnDirective field='fromsource_filename' headerText="FN from source" width='100'/>*/}
                </ColumnsDirective>

                <Inject services={[Edit, Resize, Selection]}/>
            </GridComponent>
            </div>
        </div>)

})

const AlternativeModule = forwardRef((props, ref) => {


    const [altData, setAltData] = useState([])
    const useForExpectedRef = useRef(null);
    const insideMainRef = useRef(null);
    const duplicateMainRef = useRef(null);
    const createSubRef = useRef(null);
    const showHideAltRef = useRef(null);


    useEffect(() => {
        if(props.altInfo.length !== 0){
            setAltData(props.altInfo)
        }

    }, [props.altInfo])



    const changeFormData = (e) => {
        let newVal = e.target.value.replace(/[/\\:*?"<>|\t\n\r]/g, '')
        let updateInfoData = {
            ...altData,
            [e.target.name]: newVal
        }
        setAltData(previous => updateInfoData)
        if(e.target.name === 'padding'){
            props.updateAltData(updateInfoData)
        }
    }

    const changeForKeyDown = (e) => {
        if(e.keyCode === 13){
            props.updateAltData(altData)
        }
    }

    const handleBlur = () => {
        props.updateAltData(altData)
      };

    const handleCheckboxChange = (e) => {
        let insideMain = insideMainRef.current.checked ? '1' : '0';
        if(e.target === duplicateMainRef.current) {
            insideMain = duplicateMainRef.current.checked ? '0' : '1';
        }   else if(e.target === insideMainRef.current) {
            insideMain = insideMainRef.current.checked ? '1' : '0';
        }
        let updateInfoData = {
            ...altData,
            create_subfolders: createSubRef.current.checked && insideMain === '1' ? '1' : '0',
            inside_main_structure: insideMain,
            use_for_expected: useForExpectedRef.current.checked ? '1' : '0',
            showHide: showHideAltRef.current.checked
        }
        setAltData(updateInfoData)
        props.updateAltData(updateInfoData)
    }

    /*const handleCheckboxSwitch = (e) => {
        let updateInfoData = {
            ...altData,
            create_subfolders: 0,
            [e.target.name]: e.target.checked,
            inside_main_structure: altData.inside_main_structure === true ? false : true,
            use_for_expected: useForExpectedRef.current.checked ? '1' : '0'
        }
        setAltData(previous => updateInfoData)
        props.updateAltData(updateInfoData)

    }*/

    const submitHandler = (e) => {
        e.preventDefault()
        let newAltData = {...altData}
        props.updateAltData(newAltData)
    }

    const loadData = (data) => {
        setAltData(data)
    }

    useImperativeHandle(ref, () => ({
        updateDataSource: loadData,
        getAltsData: () => altData
    }))

    if(altData.length !== 0){

        return (
            <>
            <form onSubmit={submitHandler}>
                <div style={{position: 'relative', border: '1px solid #CCC', padding: '4px 6px 6px 6px', borderRadius: '4px', fontSize: '12px'}}>
                    <div style={{position: 'absolute', float: 'right', right: '30px'}} className="form-check">
                        <label className="form-check-label" htmlFor="showAlts">
                        Show Alts Sample
                        </label>
                        <input ref={showHideAltRef} name="showHide" className="form-check-input" type="checkbox" id="showAlts" value={altData.showHide} onChange={handleCheckboxChange}/>
                    </div>
                    <b>Alternatives</b>
                    <div className="container">
                        <div className="row">
                            <div className="" style={{width: '260px'}}>
                            <div className="row">
                                <div className="col-6">
                                    <div>Expected Suffix</div>
                                    <input name="expected_sufix" type="text" className="form-control form-control-sm" value={altData.expected_sufix} onKeyDown={changeForKeyDown} onBlur={handleBlur} onChange={changeFormData} autoComplete="off" spellCheck="false"/>
                                </div>
                                <div className="col-6">
                                    <div>Final Suffix</div>
                                    <input name="final_sufix" type="text" className="form-control form-control-sm" value={altData.final_sufix} onKeyDown={changeForKeyDown} onBlur={handleBlur} onChange={changeFormData} autoComplete="off" spellCheck="false"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div style={{marginTop: '11px'}}>Num Pad:</div>
                                    <select name="padding" style={{display: 'inline', width: '100px'}} className="form-select form-select-sm" id="altPad" value={altData.padding} onChange={changeFormData}>
                                        <option value="1">1 digit</option>
                                        <option value="2">2 digits</option>
                                        <option value="3">3 digits</option>
                                        <option value="4">4 digits</option>
                                        <option value="5">5 digits</option>
                                        <option value="6">6 digits</option>
                                        <option value="7">7 digits</option>
                                        <option value="8">8 digits</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col" style={{position: 'relative', border: '1px solid #CCC', padding: '2px 6px 6px', borderRadius: '4px', fontSize: '12px', background: '#f7f7f7'}}>
                            <div className="row">
                                <div className="col-12" style={{height: '40px', display: 'flex', justifyContent: 'space-between'}}>
                                    <b>Alternative Location (Final structure)</b>
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="UseForExp">
                                            Use also for Expected
                                        </label>
                                        <input ref={useForExpectedRef} name="use_for_expected" className="form-check-input" type="checkbox" id="UseForExp"  checked={altData.use_for_expected === '1'} onChange={handleCheckboxChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="InsideMainStructure1">
                                            Inside main structure
                                        </label>
                                        <input ref={insideMainRef} name="inside_main_structure" className="form-check-input" type="checkbox" id="InsideMainStructure1"  checked={altData.inside_main_structure === '1'} onChange={handleCheckboxChange} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="InsideMainStructure2">
                                            Duplicate main structure
                                        </label>
                                        <input ref={duplicateMainRef} name="inside_main_structure" className="form-check-input" type="checkbox" id="InsideMainStructure2" checked={altData.inside_main_structure === '0'} onChange={handleCheckboxChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="createSub">
                                            Create alts subfolders
                                        </label>
                                        <input ref={createSubRef} name="create_subfolders" className="form-check-input" type="checkbox" id="gridCheck" checked={altData.create_subfolders === '1'} onChange={handleCheckboxChange} disabled={altData.inside_main_structure === '0'} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-inline">
                                        <label htmlFor="altFolderName">Folder name:</label>
                                        <input name="alts_foldername" style={{display: 'inline', width: '100px', marginLeft:'10px'}} type="text" className="form-control form-control-sm" id="altFolderName" value={altData.alts_foldername} onKeyDown={changeForKeyDown} onBlur={handleBlur} onChange={changeFormData}
                                                    disabled={altData.inside_main_structure === '1' && altData.create_subfolders === '0'
                                                        ? true
                                                        : false

                                                    } autoComplete="off" spellCheck="false"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </>

        )
    }

})

function elaborateLanguageRulesLanguage(data){

    if((data === null) || (data === undefined)){
        return []
    }

    data.forEach(function(el, idx){
        el.MyId = idx
        if(el.language_code_1 === null){el.language_code_1 = ''}
        if(el.language_code_2 === null){el.language_code_2 = ''}
    })
    return data
}


function elaborateLanguageRules(data){

    if((data === null) || (data === undefined)){
        return []
    }

    data.forEach(function(el, idx){
        el.MyId = idx
        if(el.file_description === null){el.file_description = ''}
        if(el.expected_pattern === null){el.expected_pattern = ''}
        if(el.final_pattern === null){el.final_pattern = ''}
        if(el.specs_extension === null){el.specs_extension = ''}
        if(el.specs_lufs_ref !== null){el.specs_lufs_ref = parseFloat(el.specs_lufs_ref)}
        if(el.specs_lufs_negative !== null){el.specs_lufs_negative = parseFloat(el.specs_lufs_negative)}
        if(el.specs_lufs_positive !== null){el.specs_lufs_positive = parseFloat(el.specs_lufs_positive)}
        if(el.specs_max_truepeak !== null){el.specs_max_truepeak = parseFloat(el.specs_max_truepeak)}
    })
    return data
}

function elaboratedDataPreview(languageSelection, presetSelection, data, alternative, actorsData){

    if(presetSelection === undefined){

        data.forEach(function(el, idx){
            el.MyId = idx
        })

    } else {
        data.forEach(function(el, idx){
            el.expected_pattern = elaborateExpected('expected_pattern', el, languageSelection, presetSelection, alternative, actorsData)
            el.final_pattern = elaborateExpected('final_pattern', el, languageSelection, presetSelection, alternative, actorsData)
            el.MyId = idx
        })
    }
    return data
}

function elaborateExpected(typeOfColumn, currLine, language, presetSelection, alternative, actorsData){

    let updatedString = presetSelection[typeOfColumn]

    if(updatedString === null){
        return ''
    }

    const actorId = currLine.character_id + '|' + language.id;
    const actor = actorsData?.current?.[actorId] ?? 'Undefined';
    currLine.actor_name = actor;

    updatedString = updatedString.replace(new RegExp("<LangCode1>", "g"), language.language_code_1 ? language.language_code_1 : '');// replace language code 1 and 2
    updatedString = updatedString.replace(new RegExp("<LangCode2>", "g"), language.language_code_2 ? language.language_code_2 : '');// replace language code 1 and 2

    updatedString = updatedString.replace(new RegExp("<Character>", "g"), currLine.character_name ? currLine.character_name : '');// replace character name
    updatedString = updatedString.replace(new RegExp("<Actor>", "g"), actor ? actor : '');// replace Actor name
    updatedString = updatedString.replace(new RegExp("<Path>", "g"), currLine.filename_path ? currLine.filename_path : '');// replace filename
    updatedString = updatedString.replace(new RegExp("<Filename>", "g"), currLine.filename ? currLine.filename : '');// replace filename
    updatedString = updatedString.replace(new RegExp("<Filename2>", "g"), currLine.filename2 ? currLine.filename2 : '');// replace filename

    updatedString = updatedString.replace(new RegExp("<LineId>", "g"), currLine.id ? currLine.id : '');// replace line id
    updatedString = updatedString.replace(new RegExp("<Order>", "g"), currLine.script_order ? currLine.script_order : '');// replace line order

    //updatedString = updatedString.trim().replace(/[:*?"|\t\n\r]/g, '').replace(/\\/g, '/').replace(/\.+\//g, '/').replace(/\/{2,}/g, '/');

    let outputString = updatedString

    /* alternatives */
    if(alternative.showHide === false){
        return outputString.trim().replace(/[:*?"<>|\t\n\r]/g, '').replace(/\\/g, '/').replace(/\.+\//g, '/').replace(/\/{2,}/g, '/') + presetSelection.specs_extension;
    } else {
        let altSuffix = "";

        altSuffix = alternative.expected_sufix
        if(typeOfColumn === 'final_pattern'){
            altSuffix = alternative.final_sufix
        }
        altSuffix = altSuffix + "1".padStart(alternative.padding, '0')
        if(typeOfColumn === 'final_pattern' || alternative.use_for_expected === '1'){
            const altFolderName = alternative.alts_foldername ? alternative.alts_foldername : 'ALTS';
            // inside main structure
            if(alternative.inside_main_structure === '1'){

                let updatedStringParse = path.parse(updatedString + altSuffix)

                if(alternative.create_subfolders === '1'){
                    /* create subfolders */
                    outputString = path.join(updatedStringParse.dir, altFolderName, updatedStringParse.base)
                } else {
                    /* no subfolders */
                    outputString = path.join(updatedStringParse.dir, updatedStringParse.base)
                }
            } else {
                // duplicate main structure
                outputString = path.join(altFolderName, updatedString + altSuffix)
            }

        } else {
            // only for expected pattern
            outputString = updatedString + altSuffix
        }
    }

    return outputString.trim().replace(/[:*?"<>|\t\n\r]/g, '').replace(/\\/g, '/').replace(/\.+\//g, '/').replace(/\/{2,}/g, '/') + presetSelection.specs_extension;
}


/* validation json for database */

/*file_description
expected_pattern
final_pattern
specs_extension*/

function checkJSONArray(jsonArray) {
    for (let element of jsonArray) {
        if (!element.file_description) {
            return '"FileType" column must be filled';
        }
        if (!element.expected_pattern) {
            return '"Expected filename pattern" column must be filled';
        }
        if (!element.final_pattern) {
            return '"Final filename pattern" column must be filled';
        }
        if (!element.specs_extension) {
            return '"Extension" column must be filled';
        }
    }
    return true; // Tutti i campi obbligatori sono stati riempiti
}


function checkDoubleLanguages(languages, preset, results, typeOfRequest, actorsData){

    const expectedPattern = []
    const finalPattern = []
    let presetActive = preset.filter(item => item.active === '1')

    let alternative = {}
    alternative.showHide = false

    languages.forEach(function(el_Lang, idx){

        presetActive.forEach(function(el_Preset, idx){

            results.forEach(function(el_Result, idx){

                expectedPattern.push(elaborateExpected('expected_pattern', el_Result, el_Lang, el_Preset, alternative, actorsData))
                finalPattern.push(elaborateExpected('final_pattern', el_Result, el_Lang, el_Preset, alternative, actorsData))

            })
        })
    })

    // check duplicates in expected pattern

    let expectedPatternDuplicates = expectedPattern.filter((item, index) => expectedPattern.indexOf(item) !== index)
    if(expectedPatternDuplicates.length > 0){
        if(typeOfRequest === 'languages'){
            return <div>There are duplicates in the "Expected filename pattern" column between languages.<br/>Add "Language code 1 or 2" to the pattern to avoid duplicates</div>
        }
        if(typeOfRequest === 'single'){
            return <div>There are duplicates in the "Final filename pattern" column into the same language.<br/>Add "Filename/Line Id/Script Order" to the pattern to avoid duplicates</div>
        }
    }

    // check duplicates in final pattern
    let finalPatternDuplicates = finalPattern.filter((item, index) => finalPattern.indexOf(item) !== index)
    if(finalPatternDuplicates.length > 0){
        if(typeOfRequest === 'languages'){
            return <div>There are duplicates in the "Expected filename pattern" column between languages.<br/>Add "Language code 1 or 2" to the pattern to avoid duplicates</div>
        }
        if(typeOfRequest === 'single'){
            return <div>There are duplicates in the "Final filename pattern" column into the same language.<br/>Add "Filename/Line Id/Script Order" to the pattern to avoid duplicates</div>
        }
    }

    return true
}



const LUFSPanel = forwardRef((props, ref) => {

    const defaultParameters = {
        show: false,
        specs_lufs_ref: null,
        specs_lufs_negative: -2,
        specs_lufs_positive: 2,
        fixedValue: -30,
        specs_max_truepeak: -1
    }

    const editable_truePeakRef = useRef(null);
    const editable_TollNegRef = useRef(null);
    const editable_TollPosRef = useRef(null);
    const editable_fixedValueRef = useRef(null);
    

    const [lufsSettings, setLUFSSettings] = useState(defaultParameters);

    useEffect(() => {
        if(lufsSettings.show){
            const activeEl = document.activeElement;
            const titleElement = document.querySelector('.startfocus');
            if (titleElement) {
                setTimeout(() => {
                    if (activeEl && typeof activeEl.blur === 'function') {
                        activeEl.blur();
                    }
                    titleElement.focus();
                }, 500);
            }
        }
        
    },[lufsSettings])

    useImperativeHandle(ref, () => ({
        openModal: (props) => {
            
            setLUFSSettings({
                ...defaultParameters,
                show: true,
                MyId: props.MyId,
                specs_lufs_ref: (props.specs_lufs_ref === null && props.specs_lufs_ref === undefined) ? defaultParameters.specs_lufs_ref : props.specs_lufs_ref > 0 ? props.specs_lufs_ref : 3,
                fixedValue: props.specs_lufs_ref < 0 ? props.specs_lufs_ref : defaultParameters.fixedValue,
                specs_lufs_negative: props.specs_lufs_negative !== null ? props.specs_lufs_negative : defaultParameters.specs_lufs_negative,
                specs_lufs_positive: props.specs_lufs_positive !== null ? props.specs_lufs_positive : defaultParameters.specs_lufs_positive,
                specs_max_truepeak: props.specs_max_truepeak !== null ? props.specs_max_truepeak : defaultParameters.specs_max_truepeak,
            })
        },
        closeModal: () => {
            closeModal()
        }
    }))

    const setLufsMode = (value) => {
        setLUFSSettings({
            ...lufsSettings,
            specs_lufs_ref: value
        })
    }

    const closeModal = () => {
        setLUFSSettings(prev => defaultParameters)
    }

    const applySettings = () => {
        props.onApplyLufsSettings(lufsSettings)
        setLUFSSettings(prev => defaultParameters)
    }

    const handValue = (ref, e) => {

        if(ref.current.classList.contains('onEdit')) return;
        
        const input = document.createElement('input')
        input.type = 'text'
        input.value = ref.current.innerText
        input.classList.add('handValues')
        //input.style.width = '80px'
        input.onblur = () => {
            const parent = ref.current.getAttribute('name');
            ref.current.innerText = validateValues(input.value, parent)
            
            input.remove()
            ref.current.classList.remove('onEdit')
        }
        input.onkeydown = (ev) => {
            if (ev.key === 'Enter') {
                ev.preventDefault()
                const parent = ref.current.getAttribute('name');
                ref.current.innerText = validateValues(input.value, parent)
                
                ref.current.classList.remove('onEdit')
                //input.remove()
            }
        }
        ref.current.innerHTML = ''
        ref.current.appendChild(input)
        ref.current.classList.add('onEdit')
        input.focus()
    }


    const validateValues = (value, useStateKey) => {
        let valueToCheck = value

        if(useStateKey === 'specs_max_truepeak'){valueToCheck = validateValue(value, -10, -70, 0, 'negative')}
        if(useStateKey === 'specs_lufs_negative'){valueToCheck = validateValue(value, -2, -16, 0, 'negative')}
        if(useStateKey === 'specs_lufs_positive'){valueToCheck = validateValue(value, 2, 0, 16, 'positive')}
        if(useStateKey === 'fixedValue'){valueToCheck = validateValue(value, -23, -70, 0, 'negative')}
        

        setLUFSSettings({
            ...lufsSettings,
            [useStateKey]: valueToCheck
        })
        return valueToCheck
    }

    const validateValue = (value, alternative, min, max, positive) => {

        let setValue = isNaN(parseFloat(value)) ? alternative : parseFloat(value)

        if (positive === 'negative' && setValue > 0) {
            setValue = -setValue
        }

        if (setValue < min) {
            setValue = min
        } else if (setValue > max) {
            setValue = max
        }

        return parseFloat(setValue.toFixed(1));
    }



    return lufsSettings.show && 
    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block', zIndex: '1055' }}>
    <div className="modal-dialog modal-content-lufs " role="document">
      <div className="modal-content">
        <div className="modal-header" style={{padding: '7px 10px 5px 16px'}}>
          <h5 className="modal-title startfocus">Audio Levels</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
        </div>
        <div className="modal-body" style={{fontSize: '13px', paddingBottom: '0px'}}>
        <h4 className="text-center">LUFS</h4>
        <div className="row">
                    <div className="col-4">
                        <div className="btn-group-vertical mt-3" data-bs-toggle="buttons" style={{width: '100%'}}>
                            <button className={lufsSettings.specs_lufs_ref === null ? 'btn btn-sm btn-primary' : "btn btn-sm btn-outline-primary"} onClick={() => setLufsMode(null)}>None</button>
                            <button className={lufsSettings.specs_lufs_ref === 1 ? 'btn btn-sm btn-primary' : "btn btn-sm btn-outline-primary"} onClick={() => setLufsMode(1)}>From Source</button>
                            <button className={lufsSettings.specs_lufs_ref === 2 ? 'btn btn-sm btn-primary' : "btn btn-sm btn-outline-primary"} onClick={() => setLufsMode(2)}>From Column</button>
                            <button className={lufsSettings.specs_lufs_ref === 3 ? 'btn btn-sm btn-primary' : "btn btn-sm btn-outline-primary"} onClick={() => setLufsMode(3)}>Fixed</button>
                        </div>
                    </div>
                    <div className="col-8">
                        {lufsSettings.specs_lufs_ref === null ?

                        <div style={{marginTop: '20px'}}>
                            <p>Select the modality of the LUFS</p> 
                            <div>From Source: LUFS is calculated from the source file</div>
                            <div>From Column: LUFS is calculated from the column</div>
                            <div>Fixed: set the wanted values</div>
                        </div>
                        :
                        <>
                        <div>
                            {lufsSettings.specs_lufs_ref === 1 && <div>Tolerance of the LUFS starting from Source files</div>}
                            {lufsSettings.specs_lufs_ref === 2 && <div>Tolerance of the LUFS starting from Script column</div>}
                            {lufsSettings.specs_lufs_ref === 3 && <div>Tolerance of the LUFS starting from fixed value</div>}
                        </div>
                        
                        <div className="e-slider-container">
                            
                            {lufsSettings.specs_lufs_ref === 3 && 
                            <div className="sliderPlaces">
                                <b>Fixed value (
                                    <i className="bx bx-message-square-edit"></i>
                                    <span className="editableValue" 
                                        ref={editable_fixedValueRef}
                                        name="fixedValue"
                                        onDoubleClick={() => handValue(editable_fixedValueRef)}>
                                        {lufsSettings.fixedValue}
                                    </span>dB)</b>
                                <SliderComponent 
                                id='slider' 
                                min={-70} 
                                max={0}  
                                step={0.1} 
                                value={lufsSettings.fixedValue}
                                type="MinRange"
                                //tooltip={{ placement: "Before", isVisible: true, showOn: "Always"  }} // Tooltip always visible
                                ticks={{
                                    placement: "Before",
                                    largeStep: 5,
                                    smallStep: 5,
                                    showSmallTicks: false
                                }}
                                change={(e) => setLUFSSettings({
                                    ...lufsSettings,
                                    fixedValue: e.value
                                })}
                                />
                            </div>}
                            
                            <div className="sliderPlaces"> 
                                <b>LUFS Tolerance (
                                    <i className="bx bx-message-square-edit"></i>
                                    <span className="editableValue" 
                                        ref={editable_TollNegRef}
                                        name="specs_lufs_negative"
                                        onDoubleClick={() => handValue(editable_TollNegRef)}>
                                        {lufsSettings.specs_lufs_negative}
                                    </span>
                                    /
                                    <span className="editableValue" 
                                        ref={editable_TollPosRef}
                                        name="specs_lufs_positive"
                                        onDoubleClick={() => handValue(editable_TollPosRef)}>
                                        {lufsSettings.specs_lufs_positive}
                                    </span> dB)</b>              
                                <SliderComponent
                                    min={-16} 
                                    max={16}  
                                    step={0.1} 
                                    limits={{
                                        enabled: true,  // Enable limit
                                        minStart: -16,  // Minimum start for the slider
                                        minEnd: 0,      // Maximum limit for the negative range
                                        maxStart: 0,    // Minimum limit for the positive range
                                        maxEnd: 16      // Maximum end for the positive range
                                    }}
                                    value={[lufsSettings.specs_lufs_negative, lufsSettings.specs_lufs_positive]} // Initial values
                                    type="Range"  
                                    ticks={{
                                    placement: 'Before', 
                                    largeStep: 2,  // Large step value for ticks
                                    smallStep: 0.1,  // Small step for ticks
                                    showSmallTicks: true // Small ticks visible
                                    }}
                                    change={(e) => setLUFSSettings({
                                        ...lufsSettings,
                                        specs_lufs_negative: e.value[0], 
                                        specs_lufs_positive: e.value[1]
                                    })}
                                />
                            </div>
                        </div>
                        </>
                        }
                        <div>
                            {(lufsSettings.specs_lufs_ref === 1 || lufsSettings.specs_lufs_ref === 2) &&
                                <span><b>Relative Range: {lufsSettings.specs_lufs_negative} / {lufsSettings.specs_lufs_positive}</b></span>
                            }

                            {lufsSettings.specs_lufs_ref === 3 && 
                                <span><b>Final Range: {(lufsSettings.fixedValue + lufsSettings.specs_lufs_negative).toFixed(1)} / {(lufsSettings.fixedValue + lufsSettings.specs_lufs_positive).toFixed(1)}</b></span>
                            }


                        </div>
                    </div>
                    <hr className="mt-2" />
                    <h4 className="text-center">True Peak</h4>
                    <div className="row">
                        <div className="col-4">
                            Set the Maximum True Peak

                        </div>
                        <div className="col-8">
                            <div className="sliderPlacesTP">
                                <b>True Peak 
                                    (<i className="bx bx-message-square-edit"></i>
                                    <span className="editableValue" 
                                        ref={editable_truePeakRef}
                                        name="specs_max_truepeak"
                                        onDoubleClick={() => handValue(editable_truePeakRef)}>
                                        {lufsSettings.specs_max_truepeak}
                                    </span>dB)</b>
                                <SliderComponent 
                                id='slider' 
                                min={-70} 
                                max={0}  
                                step={0.1} 
                                value={lufsSettings.specs_max_truepeak}
                                type="MinRange"
                                //tooltip={{ placement: "Before", isVisible: true, showOn: "Always"  }} // Tooltip always visible
                                ticks={{
                                    placement: "Before",
                                    largeStep: 5,
                                    smallStep: 5,
                                    showSmallTicks: false
                                }}
                                change={(e) => setLUFSSettings({
                                    ...lufsSettings,
                                    specs_max_truepeak: e.value
                                })}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer mt-2">
                        <div className="col text-center">
                            <button type="submit" className="btn btn-primary btn-sm" style={{'width': '100px'}} onClick={applySettings}>
                                Apply {/*modalSettings.spinner && <span className="spinner-grow spinner-grow-sm" role="status" ></span>*/}
                            </button>
                        </div>
                    </div>
                </div>
                </div>
        </div>
      </div>
    </div>





})