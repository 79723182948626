import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { permissionLogic, takePermissionLocalStorage} from '../ApiCenter/API_GET';


const modules = [  
  {
    permission: 'createAccount',
    module_name: 'Application resources',
    icon: 'bi bi-person-check',
    to: '/appresources'
  },
  {
    permission: 'createActors',
    module_name: 'Actors',
    icon: 'bi bi-mic',
    to: '/prodresources'
  },
  {
    permission: 'createTeams',
    module_name: 'Resource Teams',
    icon: 'bi bi-people',
    to: '/teams'
  },
  {
    permission: 'manageRecBooths',
    module_name: 'Recording Rooms',
    icon: 'bi bi-box',
    to: '/recbooths'
  },
  /*{
    permission: 'setPronunciationGlossaries',
    module_name: 'Pronunciations',
    icon: 'bi bi-globe',
    to: '/pronglossaries'
  },*/
  {
    permission: 'manageSites',
    module_name: 'Sites',
    icon: 'bi bi-pin-map',
    to: '/sites'
  },
  {
    permission: 'manageClients',
    module_name: 'Clients',
    icon: 'bi bi-joystick',
    to: '/clients'
  },
  {
    permission: 'manageLanguages',
    module_name: 'Languages',
    icon: 'bi bi-translate',
    to: '/languages'
  },
  {
    permission: 'manageFranchise',
    module_name: 'Franchises',
    icon: 'bi bi-signpost-split',
    to: '/franchises'
  },
  {
    permission: 'UITemp',
    module_name: 'Roles',
    icon: 'ri-user-settings-line',
    to: '/roles'
  },
  {
    permission: 'UITemp',
    module_name: 'Roles Permission',
    icon: 'ri-git-repository-private-line',
    to: '/rolePermissions'
  },
  {
    permission: 'UITemp',
    module_name: 'S3 Manager',
    icon: 'ri-git-repository-private-line',
    to: '/s3manager'
  },
]

/* array for active in Left Menu */
export const adminLinks = () => {
  return modules.map(el => el.to) 
}

export const BreadcrumbAdmin = () => {
  return <li className="breadcrumb-item">
          <Link to={"/admin"}>Admin Panel</Link>
        </li>
}
 

export const ProjectSupportComponents = (props) => {

  const [permission, setPermissionTab] = useState(props.permissionTab ? props.permissionTab : takePermissionLocalStorage)


  
    return (
    <>
    <ul className="nav nav-tabs" id="borderedTab">
      {modules.map((m, idx) => {
        return permissionLogic(permission, m.permission) ?   
          <Link key={idx} className="nav-item" role="presentation" to={m.to}>
            <button className={"projectNav nav-link"  + (props.data === idx ? ' active' : '')} id="home-tab" data-bs-toggle="tab" data-bs-target="#bordered-home" type="button" role="tab" aria-controls="home" aria-selected="true"><i className={m.icon}></i> {m.module_name}</button>
          </Link> : null
      })}
      {/*
      {permissionLogic(permission, 'createAccount') &&    
        <Link className="nav-item" role="presentation" to={`/appresources`}>
          <button className={"projectNav nav-link"  + (props.data === 0 ? ' active' : '')} id="home-tab" data-bs-toggle="tab" data-bs-target="#bordered-home" type="button" role="tab" aria-controls="home" aria-selected="true"><i className="bi bi-person-check"></i> Application resources</button>
        </Link>
      }

      {permissionLogic(permission, 'createActors') &&  
      <Link className="nav-item" role="presentation" to={`/prodresources`}>
        <button className={"projectNav nav-link"  + (props.data === 1 ? ' active' : '')} id="profile-tab" data-bs-toggle="tab" data-bs-target="#bordered-profile" type="button" role="tab" aria-controls="profile" aria-selected="false" tabIndex="-1"><i className="bi bi-mic"></i> Actors</button>
      </Link>
      }
      
      {permissionLogic(permission, 'createTeams') &&  
      <Link className="nav-item" role="presentation" to={`/teams`}>
        <button className={"projectNav nav-link"  + (props.data === 2 ? ' active' : '')} id="profile-tab" data-bs-toggle="tab" data-bs-target="#bordered-profile" type="button" role="tab" aria-controls="profile" aria-selected="false" tabIndex="-1"><i className="bi bi-people"></i> Resource Teams</button>
      </Link>
      }

      {permissionLogic(permission, 'manageRecBooths') &&  
      <Link className="nav-item" role="presentation" to={`/recbooths`}>
        <button className={"projectNav nav-link"  + (props.data === 3 ? ' active' : '')} id="profile-tab" data-bs-toggle="tab" data-bs-target="#bordered-profile" type="button" role="tab" aria-controls="profile" aria-selected="false" tabIndex="-1"><i className="bi bi-box"></i> Recording Rooms</button>
      </Link>
      }

      {permissionLogic(permission, 'setPronunciationGlossaries') &&
      <Link className="nav-item" role="presentation" to={`/pronglossaries`}>
        <button className={"projectNav nav-link"  + (props.data === 5 ? ' active' : '')} id="profile-tab" data-bs-toggle="tab" data-bs-target="#bordered-profile" type="button" role="tab" aria-controls="profile" aria-selected="false" tabIndex="-1"><i className="bi bi-globe"></i> Pronunciations</button>
      </Link>
      }

      {permissionLogic(permission, 'setPronunciationGlossaries') &&
      <Link className="nav-item" role="presentation" to={`/sites`}>
        <button className={"projectNav nav-link"  + (props.data === 6 ? ' active' : '')} id="profile-tab" data-bs-toggle="tab" data-bs-target="#bordered-profile" type="button" role="tab" aria-controls="profile" aria-selected="false" tabIndex="-1"><i className="bi bi-pin-map"></i> Sites</button>
      </Link>
      }

      {permissionLogic(permission, 'setPronunciationGlossaries') &&
      <Link className="nav-item" role="presentation" to={`/clients`}>
        <button className={"projectNav nav-link"  + (props.data === 7 ? ' active' : '')} id="profile-tab" data-bs-toggle="tab" data-bs-target="#bordered-profile" type="button" role="tab" aria-controls="profile" aria-selected="false" tabIndex="-1"><i className="bx bxs-building-house"></i> Clients</button>
      </Link>
      }
      {permissionLogic(permission, 'setPronunciationGlossaries') &&
      <Link className="nav-item" role="presentation" to={`/languages`}>
        <button className={"projectNav nav-link"  + (props.data === 8 ? ' active' : '')} id="profile-tab" data-bs-toggle="tab" data-bs-target="#bordered-profile" type="button" role="tab" aria-controls="profile" aria-selected="false" tabIndex="-1"><i className="bi bi-translate"></i> Languages</button>
      </Link>
      }
      {permissionLogic(permission, 'setPronunciationGlossaries') &&
      <Link className="nav-item" role="presentation" to={`/franchises`}>
        <button className={"projectNav nav-link"  + (props.data === 9 ? ' active' : '')} id="profile-tab" data-bs-toggle="tab" data-bs-target="#bordered-profile" type="button" role="tab" aria-controls="profile" aria-selected="false" tabIndex="-1"><i className="bi bi-signpost-split"></i> Franchises</button>
      </Link>
      }*/}

    </ul>
    </>
    )
}

export const AdminHomePage = (props) => {

  const [permission, setPermissionTab] = useState(takePermissionLocalStorage)

  return <main id="main" className="main">
    <div className="pagetitle">
        <h1>Admin Panel</h1>
        <nav>
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to={"/"}>Home</Link>
                </li>
                <li className="breadcrumb-item active">Admin Panel</li>
            </ol>
        </nav>
    </div>


    <div className="card">
      <div className="card-body mt-3">

        <div className="row">
          {modules.some(m => permissionLogic(permission, m.permission)) ? 
          modules.map((m, idx) => {

            return permissionLogic(permission, m.permission) ? 

              <div key={idx} className="col-12 col-sm-6 col-md-4 col-lg-3" >
                <Link  className="card" to={m.to}> 
                  <div className="card-body text-center" style={{maxHeight: 140}}>
                    
                    <p className="card-text">
                    <i style={{position: 'relative', fontSize: '40px', top: 13}} className={m.icon}></i>
                    </p>
                    <h5 className="card-title" style={{margin: 0}}>{m.module_name}</h5>
                  </div>
                </Link>
              </div>
              : 
              null
          })
          : <div>You haven't any permission on this section</div>
        }
        </div>
      </div>
    </div>
  </main>

}
