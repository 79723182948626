
import { GridComponent, ColumnsDirective, ColumnDirective, Search, Edit, Inject, Filter, Toolbar, Page, CommandColumn, RowDD } from '@syncfusion/ej2-react-grids';
import { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import axios from 'axios';
import { API_ADR, SpinnerCentered, AM_socketId } from '../../ApiCenter/API_GET';
import { Link} from 'react-router-dom'

export const ProjectsAudioAssociation = forwardRef((props, ref) => {

    let leftGrid;

    const defaultSettings = {
        showModal: false,
        isLoading: false,
        selectedID: null,
        disabledButton: true,
        deletionWindow: false
    }
    
    const [leftDataSources, setLeftDataSources] = useState([])
    const [rightDataSources, setRightDataSources] = useState([])


    const [modalSettings, setModalSettings] = useState(defaultSettings)
    
    useImperativeHandle(ref, () => ({
        openSourceModal(props) {

            console.log("VEDI", props)
            setModalSettings(previous => ({
                ...previous,
                isLoading: true,
                selectedID: props.selection,
                info: props.info,
                from: props.from ?? 'projects',
                opacity: 1
            }))
        },
        removeUI() {
            setModalSettings(defaultSettings)
        }
    }));
 
    useEffect(() => {

        
        const fetchUrl = async () => {

            const result = await axios.get(API_ADR(`audioAssignationPanel=${modalSettings.selectedID}`));

            console.log("AUDIO ASSOCIATION", result.data)

            const dataTables = eleborationData(result.data.dataTable)
            setLeftDataSources(dataTables[0])
            setRightDataSources(dataTables[1])
            setModalSettings(previous => ({
                ...previous,
                isLoading: false,
                showModal: true
            }))

        };
        if(modalSettings.isLoading === true){
            fetchUrl();
        }
    }, [modalSettings]);


    if(modalSettings.isLoading === true){return <SpinnerCentered />}


    /* add new dataset */
    const addChips = (props) => {
            // add line to leftDataSources and disable the button add from rightDataSources
            const newElement = {...props}
            let counterPriority = 1
            const newLeftDataSources = [newElement, ...leftDataSources].map(item => {
                item.priority = counterPriority;
                counterPriority++;
                return item;
            });

            // add the priority value to the rightDataSources

            const newRightDataSources = rightDataSources.map((item) => {
                if(item.id === props.id){
                    item.priority = leftDataSources.length + 1
                }
                return item
            }
        )

        setLeftDataSources(newLeftDataSources)
        setRightDataSources([...newRightDataSources])

        updateSubmitButton()
    }

    /* remove dataset */
    const removeFromList = (props) => {
      
        let counterPriority = 1
        const newLeftDataSources = leftDataSources.filter(item => item.id !== props.id).map(item => {
            item.priority = counterPriority;
            counterPriority++;
            return item;
        });

        const newRightDataSources = rightDataSources.map((item) => {
            if(item.id === props.id){
                item.priority = null
            }
            return item
        })
        setLeftDataSources(previous => newLeftDataSources)
        setRightDataSources([...newRightDataSources])
        updateSubmitButton()
    }

    const updateSubmitButton = () => {
        setModalSettings(previous => ({
            ...previous,
            disabledButton: false,
        }))
    }



    function templateLine(props) {

        let iconUserGroup = props.is_group === '1' ? 'bi bi-soundwave' : 'bi bi-soundwave'

        return (
            <div style={{position: 'relative', width: '100%'}}>
            <button type="button" className="btn btn-sm btn-outline-primary btn-sm" style={{"fontSize": '0.8em', 'height': '20px', 'padding':'1px 4px'}} disabled={props.priority} onClick={() => {addChips(props)}}>Add</button>
            {props.priority !== null ? 
                <span style={{"marginLeft": "10px", 'opacity': '0.5'}}><i className={iconUserGroup}></i> {props.package_name}</span> 
                : 
                <>
                <span style={{"marginLeft": "10px", 'fontSize': '0.9em'}}><i className={iconUserGroup}></i> {props.package_name}</span>
                <span style={{position: 'relative', float: 'right', cursor: 'pointer'}} className="badge bg-danger" onClick={() => deletePackage(props)}>Delete</span>
                </>
                }    
            </div>
        )
    }

    function deletePackage (props){
        setModalSettings(previous => ({
            ...previous,
            delectionWindow: true,
            delectionProps: props,
            delectionapply: false,
            message: null
        }))
    }

    async function deletePackageConfirm(props){

        setModalSettings(previous => ({
            ...previous,
            delectionapply: true
        }))

        const dataToDB = {
            script_id: modalSettings.selectedID,
            package_id: props.id,
            socketId: AM_socketId()
        }   

        await axios.post(
            API_ADR(`deleteSourcePackage`),
            dataToDB,
            {headers: {'Content-Type': 'text/plain'}}
        ).then(function (response) {

            if(response.data.packageUsed.length > 0){
                /* already used */
                setModalSettings(previous => ({
                    ...previous,
                    delectionapply: false,
                    message: response.data.packageUsed.map(item => item.used_by).join(", "),
                }))
            } else {
                const newRightDataSources = rightDataSources.filter(item => item.id !== props.id)

                setRightDataSources(previous => newRightDataSources)       
                setModalSettings(previous => ({
                    ...previous,
                    delectionWindow: false,
                    delectionProps: null,
                    delectionapply: false
                }))
            }
        })
    }
    

    function template2(props) {
        return <button type="button" style={{'fontSize': '11px'}} className="btn e-icons e-delete" onClick={() => removeFromList(props)}></button>
    }



    /* QUERY UPDATE OR INSERT*/
    async function submitForm(){
        const headers = {
            'Content-Type': 'text/plain'
        };
        const packagesID = [];

        leftGrid.dataSource.forEach((item) => {
            packagesID.push(item.id)
        })

        setModalSettings(previous => ({
            ...previous,
            disabledButton: true,
        }))

  
        var ObjectToDB = {
            scriptid: modalSettings.selectedID,
            packagesid: packagesID,
            socketId: AM_socketId()
        }

        const link = API_ADR(`audioAssignationApply=1`)
    
            // send the username and password to the server
        // eslint-disable-next-line
        const response = await axios.post(
                link,
                JSON.stringify(ObjectToDB),
                {headers}
        ).then(function (response) {

            closePanel('reload')

        })
    }

    const closePanel = (reloadOrNot) => {

        if(modalSettings.from === 'projects'){
            setModalSettings(defaultSettings)
            props.close(reloadOrNot)
        } else {

            // Media page

            if(reloadOrNot === ''){
                setModalSettings(defaultSettings)
                return 
            }

            setModalSettings(prev => ({
                ...prev,
                opacity: 0.5,
            }))
            props.onApplyNewAudioSettings()


        }
    }

    const changeOrder = (props) => {

        const newLeftDataSources = [...leftDataSources]
        setLeftDataSources(previous => newLeftDataSources)

        setTimeout(function(){
            reorderPriotity()
        }, 100)

        setModalSettings(previous => ({
            ...previous,
            disabledButton: false,
        }))
    }

    const reorderPriotity = () => {
        let indexLine = 1
        const newData = leftDataSources.map((item, index) => {

            item.priority = indexLine
            indexLine++
            return item
            
        })
        setLeftDataSources(previous => newData)
        /*leftGrid.refresh()*/
    }


    return (
        modalSettings.showModal ?
            <div className="modal fade show" id="AudioMateMenu" tabIndex="-1" aria-modal="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" >
                    <div className="modal-content" id="AudioMateWindowMenu" style={{opacity: modalSettings.opacity}}>
                        <div className="modal-header" style={{padding: '1px 0px 0px 15px'}}>
                            <h6 className="modal-title">
                             <i className="bi bi-soundwave"></i> Associated Audio to the Script <b>{modalSettings.info.script_name} ({modalSettings.info.languages})</b>
                            </h6>
                            <button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => closePanel('')}>🗙</button>    
                        </div>
                        {modalSettings.delectionWindow && 
                        <div id="DeletionDiv" style={{position: 'absolute', width: '100%', height: '100%', background: '#0000003d', borderRadius: '6px', zIndex: 10}}>
                            <div className="card" style={{position: 'absolute', width: '600px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 15}}>
                            <div className="modal-header" style={{padding: '4px 2px 1px 12px'}}>
                                <h5 className="modal-title">
                                    Deletion Source Audio Package
                                </h5>
                            </div>
                            
                            <div className="modal-body" >
                                <span style={{'fontSize': '13px'}}>Are you sure you want to delete the package <b>{modalSettings.delectionProps.package_name}</b>?</span>
                                {modalSettings.message && <div className="alert alert-danger" style={{marginTop: '10px', fontSize: '13px'}}>
                                    Package can't be deleted. It's already used in: <b>{modalSettings.message}</b>
                                </div>}
                            </div>
                            <div className="text-center" style={{marginBottom: '10px'}}>
                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => setModalSettings(previous => ({
                                    ...previous,
                                    delectionWindow: false,
                                    delectionProps: null
                                }))}
                                title="Cancel">Cancel</button>
                                <button style={{marginLeft: '6px'}} type="button" className="btn btn-danger btn-sm" 
                                    onClick={() => deletePackageConfirm(modalSettings.delectionProps)} title="Delete the Package"
                                    disabled={modalSettings.delectionapply}>Delete {modalSettings.delectionapply && <div style={{marginLeft: '4px'}} className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Deletion...</span>
                                </div>}</button>
                            </div>
                            </div>




                        </div>}
                        <div className="modal-body">
                            
                            <span style={{'fontSize': '13px'}}>
                                Associate the packages to the batch. The order of the packages will be the order of the priority.
                                {modalSettings.info?.uploadReference && 
                                <Link 
                                    title="Open Upload page for the Source" 
                                    to={`/uploads/source-${modalSettings.info.uploadReference.project_id}`}
                                    style={{marginLeft: '10px'}}>
                                        <i className="bi bi-cloud-upload-fill" style={{color: 'green'}}></i>
                                </Link>
                                }
                                </span>
                                <div className="row g-3" style={{'height': '390px'}}>
                                    <div className="col-7 pt-3 associationTables" style={{height: '350px'}}>
                                <GridComponent 
                                    ref={g => leftGrid = g}
                                    dataSource={leftDataSources}
                                    allowRowDragAndDrop={true}
                                    rowDrop={changeOrder}
                                    width={'auto'}
                                    height={'100%'}
                                    allowTextWrap={true}
                                    textWrapSettings={{ wrapMode: 'Content' }}

                                    >
                                    <ColumnsDirective>
                                        <ColumnDirective field='package_name' headerText='Used Packages' width='190' />
                                        <ColumnDirective field='file_count' headerText='Files' width='70' textAlign='Center' isPrimaryKey={true} />
                                        <ColumnDirective field='priority' headerText='Priority' width='60' textAlign='Center' />
                                        <ColumnDirective field='file_count' template={template2} headerText='' width='60' />    
                                    </ColumnsDirective>
                                    <Inject services={[RowDD]} />
                                </GridComponent>
                                </div>
                                <div className="col-5 pt-3 associationTables" style={{height: '350px'}}>
                                <GridComponent
                                    dataSource={rightDataSources}
                                    width={'auto'}
                                    height={'100%'}

                                    >
                                    <ColumnsDirective>
                                            <ColumnDirective field='package_name' headerText='Audio Packages' template={templateLine} width='150' />
                                            <ColumnDirective field='file_count' headerText='Files' width='50' textAlign='Center' />
                                    </ColumnsDirective>
                                    
                                </GridComponent>
                                </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary btn-sm" style={{'width': '100px'}} onClick={submitForm} disabled={modalSettings.disabledButton}>Apply</button>
                                </div> 
                        </div>
                    </div>
                </div>
            </div>
            :
            null
    )
})


function eleborationData(data){

    if(data === undefined){
        return [[], []]
    }

    const conformData = []

    data.forEach((item) => {

        conformData.push({
            id: parseInt(item.id),
            priority: item.priority,
            package_name: item.package_name,
            file_count: parseInt(item.file_count),
        })
    })
    const leftData = [...conformData.sort((b, a) => b.priority - a.priority).filter((item) => item.priority !== null)]
    const rightData = conformData.sort((a, b) => b.id - a.id)

    return [leftData, rightData]


}