import React, { useState , useEffect, Suspense, /*useRef*/} from "react";
import axios from "axios";
import { decodePermission, permissionLogic, AudioMateEncryption , AudioMateDecryption, BASE_ADR, validatePassword, AM_version, scriptControlHeight, mediaControlHeight} from "./ApiCenter/API_GET";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css';
//import { Header } from "./Header/Header";
import { MenuLeft } from "./MenuLeft/MenuLeft";
//import { Footer } from "./Footer/Footer";// not used
import { ProfileSettings } from "./pages/profile/profileSettings";
import { Home } from "./pages/Home/Home";
import AES256 from 'aes-everywhere';
import  {InfoProvider}  from './ApiCenter/AM_BackGroundOP';



/* projects */
import { ProjectsShow } from "./pages/Projects/01_Projects";
/*import { BatchesShow } from "./pages/Projects/02_Batches_NOTUSED";// not used
import { ScriptsShow } from "./pages/Projects/03_Scripts_NOTUSED";// not used*/

/* Tabulation project pages */
//import { ProjectOverview } from "./pages/Projects/04_01_ProjectOverview"; // imported as lazy
//import ProjectsBD from "./pages/Projects/04_02_ProjectBreakdown";// imported as lazy
import { ProjectBreakdownfakeLink } from "./pages/Projects/04_02_ProjectBreakdown_fakeLink";

//import { ProjectsScript } from "./pages/Projects/04_03_ProjectScriptTable"; // imported as lazy
//import { ProjectScriptExcel } from "./pages/Projects/04_04_ProjectScriptExcel"; // imported as lazy
//import { ProjectGant } from "./pages/Projects/04_05_ProjectGantChart"; // imported as lazy
//import { TrelloProduction} from "./pages/Projects/04_06_Trello"; // imported as lazy
//import ProjectBDBuilder from "./pages/Projects/04_07_ProjectBDBuilder";// imported as lazy
//import MediaLibrary from "./pages/Projects/04_08_MediaFiles";// imported as lazy
import $ from "jquery";

//import ChatGPT from "./pages/Projects/ChatGPT";// imported as lazy

/* General uploader S3*/
//import SourceFilesUploader from "./pages/FileTransfer/SourceFilesUploader"; // imported as lazy
//import UploadAssets from "./pages/FileTransfer/UploadAssets"; // imported as lazy



//import DowloadAssets from "./pages/FileTransfer/DowloadAssets";// imported as lazy
//import ExternalDragDrop  from "./pages/Ideas/04_SchedulerTemp";// imported as lazy

import VideoPlayer from "./ApiCenter/AudioMateVPlayer";
//import ApplicationUsers from "./Admin/ResourceManager_ApplicationUsers"; // imported as lazy
//import ProductionUsers from "./Admin/ResourceManager_Actors"; // imported as lazy
//import GroupsManagements from "./Admin/ResourceManager_Groups"; // imported as lazy
//import RecordingStudios  from "./Admin/ResourceManager_RecordingStudios"; // imported as lazy




//import {RolesPermission} from "./Admin_superior/RolesPermission"; // imported as lazy
//import PronunciationGlossaries  from "./pages/Projects/01_Projects_Pronunciations"; // imported as lazy
//import AdaptationMarkL1 from "./Admin/ResourceManages_AdaptationMark_L1";





/* test sync fusion */

import { registerLicense } from '@syncfusion/ej2-base';


//import { GanticTestFunction } from "./pages/SyncFusionTest/Gantic";// imported as lazy
//import { ExcelModule } from "./pages/SyncFusionTest/SpreedSheet";// imported as lazy
//import { ApplicationPanel } from "./Admin_superior/Application";// imported as lazy

import { PronunciationsContextProvider } from './pages/Projects/PronunciationContext';
import { ActorScriptContextProvider } from './pages/Projects/ActorScriptContext';
//import ActorScriptPortal from './pages/Projects/ActorScriptPortal';
//import ActorScript from './pages/Projects/ActorScript';
import { SpellCheckContextProvider } from './pages/Projects/SpellCheckContext';
import { WindowSharp } from "@mui/icons-material";

/*import PlayerDetachableAudio from "./ApiCenter/DetachableAudioPLayer";
import PlayerDetachableVideo from "./ApiCenter/DetachableVideoPlayer";*/


const Header = React.lazy(() => import("./Header/Header").then(module => ({ default: module.Header })));

const ProjectOverview = React.lazy(() => import('./pages/Projects/04_01_ProjectOverview').then(module => ({ default: module.ProjectOverview })));
const ProjectsBD = React.lazy(() => import('./pages/Projects/04_02_ProjectBreakdown'));
const ProjectBDBuilder = React.lazy(() => import('./pages/Projects/04_07_ProjectBDBuilder'));
const ProjectsScript = React.lazy(() => import('./pages/Projects/04_03_ProjectScriptTable').then(module => ({ default: module.ProjectsScript })));
const ActorScriptWindow = React.lazy(() => import('./pages/Projects/04_09_ActorScript'));
const PronunciationGlossaries = React.lazy(() => import('./pages/Projects/01_Projects_Pronunciations'));
const ProjectScriptExcel = React.lazy(() => import('./pages/Projects/04_04_ProjectScriptExcel').then(module => ({ default: module.ProjectScriptExcel })));
const ProjectGant = React.lazy(() => import('./pages/Projects/04_05_ProjectGantChart').then(module => ({ default: module.ProjectGant })));
const TrelloProduction = React.lazy(() => import('./pages/Projects/04_06_Trello').then(module => ({ default: module.TrelloProduction })));
const MediaLibrary = React.lazy(() => import('./pages/Projects/04_08_MediaFiles'));
const MediaLibrary2 = React.lazy(() => import('./pages/Projects/04_08_MediaFiles_2'));

const PlayerDetachableAudio = React.lazy(() => import("./ApiCenter/DetachableAudioPLayer"));
const PlayerDetachableVideo = React.lazy(() => import("./ApiCenter/DetachableVideoPlayer"));
const AudioMassEditor = React.lazy(() => import('./ApiCenter/AM_AudioEditor'));

//const SourceFilesUploader = React.lazy(() => import('./pages/FileTransfer/SourceFilesUploader'));
const UploadAssets = React.lazy(() => import('./pages/FileTransfer/UploadAssets'));
const DowloadAssets = React.lazy(() => import('./pages/FileTransfer/DowloadAssets'));


const AdminHomePage = React.lazy(() => import('./Admin/ResourceManager_TabComponent').then(module => ({ default: module.AdminHomePage })));
const ApplicationUsers = React.lazy(() => import('./Admin/ResourceManager_ApplicationUsers'));
const ProductionUsers = React.lazy(() => import('./Admin/ResourceManager_Actors'));
const GroupsManagements = React.lazy(() => import('./Admin/ResourceManager_Groups'));
//const RecordingStudios = React.lazy(() => import('./Admin/ResourceManager_RecordingStudios'));
const RecordingStudios = React.lazy(() => import('./Admin/ResourceManager_RecordingStudios').then(module => ({ default: module.RecordingStudios })));

const AMSites = React.lazy(() => import('./Admin/ResourceManager_Sites').then(module => ({ default: module.AMSites })));
const AMClients = React.lazy(() => import('./Admin/ResourceManager_Clients').then(module => ({ default: module.AMClients })));
const AMLanguages = React.lazy(() => import('./Admin/ResourceManager_Languages').then(module => ({ default: module.AMLanguages })));
const AMFranchises = React.lazy(() => import('./Admin/ResourceManager_Franchises').then(module => ({ default: module.AMFranchises })));
const AMRoles = React.lazy(() => import('./Admin/ResourceManager_Roles').then(module => ({ default: module.AMRoles })));



const SchedulerContainer = React.lazy(() => import('./Admin/Scheduler/SchedulerContainer').then(module => ({ default: module.SchedulerContainer })));
const QATags = React.lazy(() => import('./Admin/QATags').then(module => ({ default: module.QATags })));

const RolesPermission = React.lazy(() => import('./Admin_superior/RolesPermission').then(module => ({ default: module.RolesPermission })));

const ChatGPT = React.lazy(() => import('./pages/Projects/ChatGPT'));

const S3Manager = React.lazy(() => import('./Admin/AudioManager/S3Manager').then(module => ({ default: module.S3Manager })));

const XLOCApi = React.lazy(() => import('./pages/Projects/XLOCApi'));


{/*const GanticTestFunction = React.lazy(() => import('./pages/SyncFusionTest/Gantic').then(module => ({ default: module.GanticTestFunction })));*/}
const ExcelModule = React.lazy(() => import('./pages/SyncFusionTest/SpreedSheet').then(module => ({ default: module.ExcelModule })));
const ApplicationPanel = React.lazy(() => import('./Admin_superior/Application').then(module => ({ default: module.ApplicationPanel })));


/* Metrics */

const Metrics = React.lazy(() => import('./pages/Projects/Metrics').then(module => ({ default: module.Metrics })));

/* help online */

const VideoTutorial = React.lazy(() => import('./pages/Help/videotutorial').then(module => ({ default: module.VideoTutorial })));
const AudioMatePdfGuide = React.lazy(() => import('./pages/Help/pdfguide').then(module => ({ default: module.AudioMatePdfGuide })));

// licence that we have always had
registerLicense('ORg4AjUWIQA/Gnt2VlhiQlVPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSH9Rd0RjWXheeXBWR2U=');
// waht is inside the website now 
//registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF1cX2hIYVFpR2Nbek54flFGallYVAciSV9jS3pSdEVlWXlbcXdST2RaUg==');

$(window).on("resize", function(e) {


    const current = $(window).height();
    const breakdownHeight = current - 290;
    const projectlistHeight = current - 250;

    const bdbuilderHeight = current - 320; 
    const resourceappAppHeight = current - 290; 
    const resourceappActorHeight = current - 290; 
    const resourcegroupsHeight = current - 290;
    const resourcerecstudiosHeight = current - 260;
    const positionplayerFull = current - 70;
    const rulesHeight = current - 255;
    const ganttHeight = current - 300;
    const amsites = current - 290;


    document.documentElement.style.setProperty(`--div-height-breakdown`, breakdownHeight + 'px');


 

    document.documentElement.style.setProperty(`--div-height-breakdown`, breakdownHeight + 'px');
    document.documentElement.style.setProperty(`--div-height-projectlist`, projectlistHeight + 'px');
    
    //document.documentElement.style.setProperty(`--div-height-pronunciationspanel`, pronunciationspanel +  'px');
    document.documentElement.style.setProperty(`--div-height-bdbuilder`, bdbuilderHeight + 'px');
    document.documentElement.style.setProperty(`--div-height-resourceapp`, resourceappAppHeight + 'px');
    document.documentElement.style.setProperty(`--div-height-resourceactors`, resourceappActorHeight + 'px');
    document.documentElement.style.setProperty(`--div-height-resourcegroups`, resourcegroupsHeight + 'px');
    document.documentElement.style.setProperty(`--div-height-resourcerecstudios`, resourcerecstudiosHeight + 'px');
    document.documentElement.style.setProperty(`--div-height-rules`, rulesHeight + 'px');
    document.documentElement.style.setProperty(`--div-height-ganttPlanner`, ganttHeight + 'px');
    document.documentElement.style.setProperty(`--div-height-amsites`, amsites + 'px');
    document.documentElement.style.setProperty(`--div-height-amclients`, amsites + 'px');
    scriptControlHeight(current);
    mediaControlHeight();

});

/*function hexToRgbA(hex, alpha = 1) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return (
        'rgba(' +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
        ',' +
        alpha +
        ')'
      );
    }
    throw new Error('Bad Hex');
  }*/



  function invalidCredentials(message){
    document.querySelector('#handlelogin').innerHTML = '<div class="alert alert-danger alert-dismissible fade show" role="alert">' + 
    message + 
    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>' + 
    '</div>'
  }
  function invalidUsername(){
    document.querySelector('#handlelogin').innerHTML = '<div class="alert alert-danger alert-dismissible fade show" role="alert">' + 
    'Username field must be compiled!' + 
    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>' + 
    '</div>'
  }

function App() {

    const [actor, setActor] = useState(false)
    const [player, setPlayer] = useState(false)
    const [user, setUser] = useState(false)
    const [isloading, setIsLoading] = useState(true)
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [userPermissions, setUserPermissions] = useState([]);

    /* change password */
    const [changePasswordMode, setChangePasswordMode] = useState(false)
    const [changePasswordCode, setChangePasswordCode] = useState(0)
    const [cssClassPasswordCode, setCssClassPasswordCode] = useState(false)
    const [cssClassPasswordMatch, setCssClassPasswordMatch] = useState(false)
    const [formChangePassword, setFormChangePassword] = useState(false)

    /* text/password */
    const [mainPasswordType, setMainPasswordType] = useState("password")
    const [newPasswordType, setNewPasswordType] = useState("password")
    const [confirmPasswordType, setConfirmPasswordType] = useState("password")
    const [bottonSpinner, setBottonSpinner] = useState("")

    const [showActorScriptPortal, setShowActorScriptPortal] = useState(false);
    
    // current stap time


    useEffect(() => {
      const loggedInUser = localStorage.getItem("user");
      const lastLogin = localStorage.getItem("referenceC");

      if (loggedInUser !== undefined) {

        try{

            const now = Date.now();
            const diff = now - parseInt(AudioMateDecryption(lastLogin));
    
            // if difference is more than 10 hours 36000000
            if (diff > 36000000) {
                setIsLoading(false)
                logOutUser();
    
            } else {

                /* logged in */
    
                // eslint-disable-next-line
                const foundUser = JSON.parse(loggedInUser);
                setIsLoading(false)
                setUser(true);



                axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("audioMate")}`;
                document.documentElement.style.setProperty(`--zendesstatus`, '1');
            }


        }
        catch(e){

            setIsLoading(false)
        }

      } 
      const permissions = localStorage.getItem("pem");
      if(permissions){
        setUserPermissions(decodePermission(permissions))
      }

    }, []);

    if(isloading){
        return <span>Loading...</span>
    }



    const handleSubmit = async e => {
        e.preventDefault();

        document.querySelector('#handlelogin').innerHTML = '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>'
        //setBottonSpinner(<span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>)

        const headers = {
            'Content-Type': 'text/plain'
        };

        if(username === '' || password === ''){
            invalidCredentials('Username and Password fields must be compiled!')
            return
        }

        const credentials = { username, password, versionapp: AM_version(), domain: window.location.hostname };
        // send the username and password to the server

        const response = await axios.post(
            BASE_ADR('login=1'),
        
            credentials,
            {headers}
        );

        if(response.data.vault.status === false){
            invalidCredentials(response.data.vault.message)
            
        } else {
                axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                localStorage.setItem('sessionID', response.data.sessionID)
                localStorage.setItem('keyID', response.data.keyID)
                localStorage.setItem('system', response.data.system)
                // Decode the String
                setUserPermissions(decodePermission(response.data.usepem))
                setUser(response.data)
                localStorage.setItem('user', JSON.stringify(response.data.response))
                localStorage.setItem('audioMate', response.data.token)
                localStorage.setItem('usertemp', response.data.responseEnc)
                localStorage.setItem('pem', response.data.usepem)
                //localStorage.setItem('reference', Date.now())
                localStorage.setItem('referenceC', AudioMateEncryption(Date.now()))
                document.documentElement.style.setProperty(`--zendesstatus`, '1');
        }
    }

    function logOutUser(){
        setUser(false)
        document.documentElement.style.setProperty(`--zendesstatus`, '0');
        localStorage.removeItem('user')
        localStorage.removeItem('pem')
        localStorage.removeItem('usertemp')
        localStorage.removeItem('reference')
        localStorage.removeItem('referenceC')
        localStorage.removeItem('audioMate')
        localStorage.removeItem('sessionID')
        localStorage.removeItem('keyID')
        localStorage.removeItem('system')
        setUsername("");
        setPassword("");
    }

    function refreshStatus(info){

        const permissions = localStorage.getItem("pem");

        if(permissions !== info){
            localStorage.setItem('pem', info)
            setUserPermissions(decodePermission(info))
        }
    }

    function gobackToLogin(deleteMessages){
        try{
        document.querySelector('#NewPassword').value = ''
        document.querySelector('#NewPasswordConfirm').value = ''
        } catch(e){}
        if(deleteMessages){
            document.querySelector('#handlelogin').innerHTML = ''
        }
        setChangePasswordMode(false)
        setCssClassPasswordCode(false)
        setCssClassPasswordMatch(false)
        setFormChangePassword(false)

    }

    /*show hide passwords */
    function showHidePassword(type){
        if(type === 'main'){
            if(mainPasswordType === "text"){setMainPasswordType("password")} else {setMainPasswordType("text")}
        }
        if(type === 'new'){
            if(newPasswordType === "text"){setNewPasswordType("password")} else {setNewPasswordType("text")}
        }
        if(type === 'confirm'){
            if(confirmPasswordType === "text"){setConfirmPasswordType("password")} else {setConfirmPasswordType("text")}
        }
    }

    function passwordChange(){
        const usernameValue = username.trim()
        if(usernameValue === ''){
            invalidUsername()
            return
        } else {
            document.querySelector('#handlelogin').innerHTML = '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>'
            const headers = {
                'Content-Type': 'text/plain'
            };
            const credentials = { usernameValue, any: 'any'};
            // send the username and password to the server
            axios.post(
                BASE_ADR('passwordchange=1'),
                credentials,
                {headers}
            ).then(function (response) {
                if(response.data.vault.status === true){

                    setChangePasswordMode(true)
                    document.querySelector('#handlelogin').innerHTML = '<div class="alert alert-success alert-dismissible fade show" role="alert">' + 
                    'An email has been sent to your address!' + 
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>' + 
                    '</div>'
                } else {
                    document.querySelector('#handlelogin').innerHTML = '<div class="alert alert-danger alert-dismissible fade show" role="alert">' + 
                    'Username not found!' + 
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>' + 
                    '</div>'
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
    }
    /* check if the code is valid */
    function inputValidationCode(e){
        if(e.target.value.length === 8){
            setCssClassPasswordCode(true)
            //document.querySelector('#handlelogin').innerHTML = ''
        } else {
            setCssClassPasswordCode(false)
        }
    }

    /* check if the code is valid */
    async function checkValidationCode(){
        if(cssClassPasswordCode){

            const code = document.querySelector('#checkNumberID').value
            if(code.trim().length === 8){
                document.querySelector('#handlelogin').innerHTML = '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>'
                const headers = {
                    'Content-Type': 'text/plain'
                }
                axios.post(
                    BASE_ADR('passwordCheckcode=1'),
                    {code, username: username.trim()},
                    {headers}
                ).then(function (response) {

                    if(response.data.status === false){
                        document.querySelector('#handlelogin').innerHTML = '<div class="alert alert-danger alert-dismissible fade show" role="alert">' + 
                        response.data.message + 
                        '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>' + 
                        '</div>'

                        if(response.data.retry === false){
                            gobackToLogin(false)
                        }

                    } else {
                        document.querySelector('#handlelogin').innerHTML = ''
                        setFormChangePassword(true)
                        
                    }
                })
            }
        }
    }

    /* check if new password matchs */
    function changePasswordValueHandler(e){
        const newPassword = document.querySelector('#NewPassword').value
        const newPasswordConfirm = document.querySelector('#NewPasswordConfirm').value

        if(newPassword === newPasswordConfirm){
            setCssClassPasswordMatch("green")
        } else {
            setCssClassPasswordMatch("red")
        }
    }

    function changePasswordOperation(){
        const newPassword = document.querySelector('#NewPassword').value
        const newPasswordConfirm = document.querySelector('#NewPasswordConfirm').value


        if(newPassword.trim() === ''){

            document.querySelector('#handlelogin').innerHTML = '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            'New Password fields must be compiled!' +
            '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>' +
            '</div>'
            return
        }

        document.querySelector('#handlelogin').innerHTML = '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>'

        if(newPassword === newPasswordConfirm){
            const headers = {
                'Content-Type': 'text/plain'
            };

            const url = window.location.href;

            if(url.indexOf('?easyPassword=1') !== -1){

            } else {
                const newPasswordCheck = validatePassword(newPassword)

                if(newPasswordCheck !== true){
    
                    document.querySelector('#handlelogin').innerHTML = '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
                    newPasswordCheck +
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>' +
                    '</div>'
                    return
                }
            }


            const credentials = { username,
                                newPassword: newPassword };
            // send the username and password to the server
            axios.post(
                BASE_ADR('passwordchangeoperation=1'),
                credentials,
                {headers}
            ).then(function (response) {

                if(response.data.vault.status === true){
                    //setChangePasswordMode1!
                    setChangePasswordMode(false)
                    setCssClassPasswordCode(false)
                    setCssClassPasswordMatch(false)
                    setFormChangePassword(false)
                    document.querySelector('#NewPassword').value = ''
                    document.querySelector('#NewPasswordConfirm').value = ''


                    document.querySelector('#handlelogin').innerHTML = '<div class="alert alert-success alert-dismissible fade show" role="alert">' +
                    'Password changed! <br />Enter your new data to access to AudioMate' +
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>' +
                    '</div>'
                } else {
                    document.querySelector('#handlelogin').innerHTML = '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
                    'Error!' +
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>' +
                    '</div>'
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
        } else {
            document.querySelector('#handlelogin').innerHTML = '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            'Password not match!' +
            '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>' +
            '</div>'
        }
    }

    const updatePermissions = (info) => {
        setUserPermissions(info)
    }

    if (user) {   
        return (
            <> 
            
                <InfoProvider>
                    <ActorScriptContextProvider>
                        <Router>
                            {!actor &&<Header logOutUser={logOutUser} data={userPermissions} refreshStatus={refreshStatus}/>}
                            {!actor &&<MenuLeft data={userPermissions} onRefreshApp={updatePermissions}/>}

                            <div id="audiomatecontrol">
                                <Suspense fallback={<div>Loading...</div>}>
                                    {!actor &&<>
                                        <Routes>
                                            <Route exact path="/" element={<ProjectsShow/>}/>
                                            <Route exact path="/userdata" element={<ProfileSettings/>}/>
                                        
                                            {/* -------------------------PROJECTS ROOT */}
                                            <Route exact path="/projects/:title?" element={<ProjectsShow/>}/>
                                    
                                            {permissionLogic(userPermissions, 'SourceUploadFiles') || permissionLogic(userPermissions, 'TargetUploadFiles') ?
                                                <Route path="/uploads/:uploadinfo?" element={<UploadAssets/>}/>
                                                : <></>
                                            }

                                            {permissionLogic(userPermissions, 'SourceDownloadFiles') || permissionLogic(userPermissions, 'TargetDownloadFiles') ?
                                                <Route path="/downloads/:downloadinfo?" element={<DowloadAssets/>}/>
                                                : <></>
                                            }

                                            {/*permissionLogic(userPermissions, 'view_Metrics') && <Route path="/metrics" element={<Metrics/>}/>*/}
                                            <Route path="/metrics" element={<Metrics/>} />
                                            <Route path="/videos" element={<VideoPlayer/>}/>
                                    
                                            {/*------------------------ TABULATION PROJECT */}
                                            {permissionLogic(userPermissions, 'view_Overview') && <Route exact path="/projectsdetails/:id/overview" element={<ProjectOverview/>}/>}
                                            {permissionLogic(userPermissions, 'view_Breakdown') && <Route exact path="/projectsdetails/:id/breakdown" element={<ProjectsBD/>}/>}
                                            <Route exact path="/redirectdb/:id" element={<ProjectBreakdownfakeLink/>}/>

                {permissionLogic(userPermissions, 'showQATagsModule') && <Route exact path="/qatags" element={<QATags/>}/>}
                {permissionLogic(userPermissions, 'xlocModule') && <Route exact path="/xloc" element={<XLOCApi/>}/>}
              </Routes>
              
              <PronunciationsContextProvider>             
              <SpellCheckContextProvider>             
              {permissionLogic(userPermissions, 'view_ScriptBase') &&<Routes><Route exact path="/projectsdetails/:id/script" element={<ProjectsScript data={{showActorScriptPortal, setShowActorScriptPortal}}/>}/></Routes>} 
              </SpellCheckContextProvider>
              </PronunciationsContextProvider>
              {permissionLogic(userPermissions, 'view_ExcelModule') &&<Routes><Route exact path="/projectsdetails/:id/excel" element={<ProjectScriptExcel/>}/></Routes>}
              {permissionLogic(userPermissions, 'view_GantChart') &&<Routes><Route exact path="/projectsdetails/:id/gantt" element={<ProjectGant/>}/></Routes>}
              {permissionLogic(userPermissions, 'view_BreakDownBuilder') &&<Routes><Route exact path="/projectsdetails/:id/bdbuilder" element={<ProjectBDBuilder/>}/></Routes>}
              {permissionLogic(userPermissions, 'view_Trello') &&<Routes><Route exact path="/projectsdetails/:id/production" element={<TrelloProduction/>}/></Routes>}
              {/*{permissionLogic(userPermissions, 'view_Media') &&<Routes><Route exact path="/projectsdetails/:id/medianewmedia" element={<MediaLibrary/>}/></Routes>*/}
              {permissionLogic(userPermissions, 'view_Media') &&<Routes><Route exact path="/projectsdetails/:id/media" element={<MediaLibrary2/>}/></Routes>}

              
              {/* resources */}
              {/*permissionLogic(userPermissions, 'createAccount') &&<Routes><Route exact path="/admin" element={<AdminHomePage/>}/></Routes>*/}
              <Routes><Route exact path="/admin" element={<AdminHomePage/>}/></Routes>

              {permissionLogic(userPermissions, 'createAccount') &&<Routes><Route exact path="/appresources" element={<ApplicationUsers/>}/></Routes>}
              {permissionLogic(userPermissions, 'manageSites') &&<Routes><Route exact path="/sites" element={<AMSites/>}/></Routes>}
              {permissionLogic(userPermissions, 'manageClients') &&<Routes><Route exact path="/clients" element={<AMClients/>}/></Routes>}
              {permissionLogic(userPermissions, 'manageLanguages') &&<Routes><Route exact path="/languages" element={<AMLanguages/>}/></Routes>}
              {permissionLogic(userPermissions, 'manageFranchise') &&<Routes><Route exact path="/franchises" element={<AMFranchises/>}/></Routes>}
              {permissionLogic(userPermissions, 'UITemp') &&<Routes><Route exact path="/roles" element={<AMRoles/>}/></Routes>}
              

              {permissionLogic(userPermissions, 'createActors') &&<Routes><Route exact path="/prodresources" element={<ProductionUsers/>}/></Routes>}
              {permissionLogic(userPermissions, 'createTeams') &&<Routes><Route exact path="/teams" element={<GroupsManagements/>}/></Routes>}
              {permissionLogic(userPermissions, 'manageRecBooths') &&<Routes><Route exact path="/recbooths" element={<RecordingStudios/>}/></Routes>}
              {/*<Routes><Route exact path="/gantt" element={<Gantt/>}/></Routes>*/}
              {/*permissionLogic(userPermissions, 'setAdaptationMarks') &&<Routes><Route exact path="/adaptMarks" element={<AdaptationMarkL1/>}/></Routes>*/}
              {permissionLogic(userPermissions, 'setPronunciationGlossaries') && <Routes><Route exact path="/pronglossaries" element={<PronunciationGlossaries/>}/></Routes>}
              {permissionLogic(userPermissions, 'view_Scheduler') && <Routes><Route exact path="/scheduler" element={<SchedulerContainer/>}/></Routes>}
                                        
                                        {/* resources temporany */}
                                        {permissionLogic(userPermissions, 'UITemp') &&(
                                            <>
                                                <Routes><Route exact path="/rolePermissions" element={<RolesPermission/>}/></Routes>
                                                <Routes><Route exact path="/application" element={<ApplicationPanel/>}/></Routes>

                    {/*<Routes><Route exact path="/gantic" element={<GanticTestFunction/>}/></Routes>*/}
                                                <Routes><Route exact path="/spreedsheet" element={<ExcelModule/>}/></Routes>
                                                <Routes><Route exact path="/s3manager" element={<S3Manager/>}/></Routes>
                                                
                                                <Routes><Route exact path="/chatai" element={<ChatGPT/>}/></Routes>
                                    
                                            </>
                                        )
                                        }
                {/* help */}

                                        <Routes><Route exact path="/help" element={<VideoTutorial/>}/></Routes>
                                        <Routes><Route exact path="/documentation/:id" element={<AudioMatePdfGuide/>}/></Routes>
                                    </>}
                                    {/*<Footer />*/}
                                    {permissionLogic(userPermissions, 'view_ScriptBase') &&<Routes><Route exact path="/actorscript" element={<ActorScriptWindow data={{set:setActor}}/>}/></Routes>}
                                    <Routes><Route exact path="/AudiomatePlayer" element={<PlayerDetachableAudio />}/></Routes>
                                    <Routes><Route exact path="/AudioMateVPlayer" element={<PlayerDetachableVideo /*data={{set:setPlayer}}*//>}/></Routes>
                                    <Routes><Route exact path="/AudiomateEditor" element={<AudioMassEditor />}/></Routes>
                                </Suspense>
                            </div>
                        </Router>
              
                        {/*showActorScriptPortal && (
                            <ActorScriptPortal onWindowClose={() => setShowActorScriptPortal(false)}>
                                <ActorScript/>
                            </ActorScriptPortal>
                            )
                        */}
                    </ActorScriptContextProvider>
                </InfoProvider> 
               
            </>

        )
    } else {
        // if there's no user, show the login form
        return (

            <>
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                          <div className="d-flex justify-content-center py-4">
                              <a href="index.html" className="logo d-flex align-items-center w-auto">
                                  <img src="assets/img/kantan-audiomate_logo-color.png" alt="" />
                                   {/*<span className="d-none d-lg-block">AudioMate</span>*/}
                              </a>
                          </div>
                          <div className="card mb-3">
                              <div className="card-body">
                                  <div className="pt-4 pb-2">
                                      <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                      <p className="text-center small">Enter your username &amp; password to login</p>
                                  </div>
                                  <form className="row g-3 needs-validation" noValidate="" onSubmit={handleSubmit}>
                                    {changePasswordMode === false &&
                                      <>                                    
                                      <div className="col-12">
                                          <label htmlFor="Username" className="form-label">Username</label>
                                          <div className="input-group has-validation">
                                              <span className="input-group-text" id="inputGroupPrepend"><i className="bx bxs-user-circle"></i></span>
                                              <input type="text" name="username" className="form-control" id="Username" value={username} required="" onChange={({ target }) => setUsername(target.value)} autoComplete="off" spellCheck="false"/>
                                              <div className="invalid-feedback">Please enter your username.</div>
                                          </div>
                                      </div>

                                      <div className="col-12">
                                          <label htmlFor="Password" className="form-label">Password</label>
                                          <div className="input-group mb-3">
                                            <input type={mainPasswordType} name="password" className="form-control" id="Password" value={password} required="" onChange={({ target }) => setPassword(target.value)}/>
                                            <span className="input-group-text asimulatedlink" onClick={() =>showHidePassword('main')}><i className="bi bi-eye-slash"></i></span>
                                          </div>
                                          <div className="invalid-feedback">Please enter your password!</div>
                                      </div>
                                      <div className="col-12 t-20 text-end">
                                          <span className="asimulatedlink" onClick={passwordChange}>Change Password</span>
                                      </div>
                                      </>}
                                      {/********************************************************************** */}
                                      {changePasswordMode === true &&
                                      <>
                                      <div className="col-12 t-20 text-end">
                                          <span className="asimulatedlink" onClick={() => gobackToLogin(true)}>Go Back</span>
                                      </div>
                                      <div className="col-12">
                                          <label htmlFor="checkNumberID" className="form-label">Insert the code sent at your email address</label>
                                          <div className="btn-group text-center" role="group" aria-label="ResetPassword" style={{textAlign: 'center'}}>
                                                <input type="text" id="checkNumberID" className="form-control form-control-sm" style={{width: '100px', color: cssClassPasswordCode ? 'green' : 'red'}} onChange={inputValidationCode} disabled={formChangePassword} />
                                                <button type="button" className="btn btn-outline-primary btn-sm" style={{width: '100px'}} onClick={checkValidationCode} disabled={!cssClassPasswordCode || formChangePassword}>Validate</button>
                                          </div>
                                          <div className="invalid-feedback">Please enter your password!</div>
                                      </div>
                                      {/********PASSWORD************************************************************** */}
                                      </>}
                                      {formChangePassword &&
                                      (<>

                                        <div className="col-12">
                                          <label htmlFor="NewPassword" className="form-label">New Password</label>
                                          <div className="input-group mb-3">
                                            <input type={newPasswordType} name="password" className="form-control" id="NewPassword" style={{color: cssClassPasswordMatch}} required="" onChange={changePasswordValueHandler}/>
                                            <span className="input-group-text asimulatedlink" onClick={() =>showHidePassword('new')}><i className="bi bi-eye-slash"></i></span>
                                        </div>

                                      </div>
                                      {/**********CONFIRM PASSWORD************************************************************ */}
                                      <div className="col-12">
                                          <label htmlFor="NewPasswordConfirm" className="form-label">New Password Confirm</label>
                                          <div className="input-group mb-3">
                                            <input type={confirmPasswordType} name="password" className="form-control" id="NewPasswordConfirm" style={{color: cssClassPasswordMatch}} required="" onChange={changePasswordValueHandler}/>
                                            <span className="input-group-text asimulatedlink" onClick={() =>showHidePassword('confirm')}><i className="bi bi-eye-slash"></i></span>
                                          </div>
                                      </div>

                                      <div className="col-12 t-20">
                                          <label htmlFor="button" className="form-label"></label>
                                          <button name="button" className="btn btn-primary w-100" type="button" onClick={changePasswordOperation}>Change Password</button>
                                      </div>
                                        </>)}
                                      {/*************END********************************************************* */}
                                      
                                      {changePasswordMode === false &&
                                      <>
                                      <div className="col-12 t-20">
                                          <label htmlFor="button" className="form-label"></label>
                                          <button name="button" className="btn btn-primary w-100" type="submit">Login {bottonSpinner}</button>
                                      </div>
                                      </>}
                                      <div id="handlelogin"></div>

                                  </form>
                              </div>
                              
                          </div>

                      </div>
                  </div>
              </div>
          </>
        );

    }

  
}

export default App;
