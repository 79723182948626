import { NavLink, useLocation } from "react-router-dom";
import { API_ADR, parseUsername, permissionLogic, decodePermission, extractValuesFromLocalStorage } from "../ApiCenter/API_GET";
import { useEffect, useState } from "react";
import axios from "axios";
import {adminLinks} from '../Admin/ResourceManager_TabComponent'

const ciao = 1;

export const MenuLeft = (props) => {
  const [dataPermission, setdataPermission] = useState(props.data);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [internal, setInternal] = useState(0);

  useEffect(() => {

    setInternal(extractValuesFromLocalStorage().internal)
  }, [])
  
  const location = useLocation();

  const expandCollapseLeftMenu = () => {

    const refreshMenu = async () => {
      const result = await axios.get(API_ADR(`refreshMenu=1`));
      const decodePem = decodePermission(result.data)
      setdataPermission(decodePem)
      props.onRefreshApp(decodePem)
    }



    refreshMenu();

    const sidebar = document.querySelector('.sidebar');
    const mainContent = document.querySelector('#audiomatecontrol');

    if (sidebar.classList.contains('expanded')) {
      sidebar.classList.remove('expanded');
      mainContent.classList.remove('menuexpanded');
    } else {
      sidebar.classList.add('expanded');
      mainContent.classList.add('menuexpanded');
    }
  }


  const isProjectsActive = () => {
      if (location.pathname.includes('/projectsdetails') || location.pathname === '/'){
        return true
      }
      return false
  };

  return (
    <div className="sidebar">
        
    <ul className="list-unstyled">
      <div className="expand-collapse-icon">
        <i className="closeopen-icon bi bi-arrow-right-circle" onClick={expandCollapseLeftMenu}></i>
        <i className="closeopen-icon bi bi-arrow-left-circle" onClick={expandCollapseLeftMenu}></i>
        <span className="menu-description">Menu</span> 
      </div>
      {/*<Link to={'/'}>
      <li
        className={`d-flex align-items-center ${hoveredItem === 0 ? 'expanded' : ''}`}
        
        onMouseEnter={() => setHoveredItem(0)}

      >
        <i className="bi bi-house-door"

        ></i>
        <span className="ms-2">Home</span>
      </li>
  </Link>*/}

      <NavLink to={'/projects'} className={isProjectsActive() ? 'active' : ''}>
      <li
        className={`d-flex align-items-center`}
        

        
      >

        <i className="bi bi-grid-fill"
                
        ></i>
        <span className="ms-2">Projects</span>
      </li>
      </NavLink>
      {/* UPLOADS */}
      {permissionLogic(dataPermission, 'SourceUploadFiles') || permissionLogic(dataPermission, 'TargetUploadFiles') ? (

            <NavLink to={'/uploads'} exact="true" activeclassname="active">
            <li
              className={`d-flex align-items-center`}
              
      
              
            >
              <i className="bi bi-cloud-upload-fill"></i>
              <span className="ms-2">Uploads</span>
            </li>
            </NavLink>

      
      ) : null}
      {/* DOWNLOADS */}
      {permissionLogic(dataPermission, 'SourceDownloadFiles') || permissionLogic(dataPermission, 'TargetDownloadFiles') ? (
      <NavLink to={'/downloads'} exact="true" activeclassname="active">
      <li
        className={`d-flex align-items-center`}
        

        
      >
        <i className="bi bi-cloud-download-fill"></i>
        <span className="ms-2">Downloads</span>
      </li>
      </NavLink>) : null}

      {permissionLogic(dataPermission, 'setPronunciationGlossaries') &&
      (<NavLink to={'/pronglossaries'} exact="true" activeclassname="active">
      <li
        className={`d-flex align-items-center`}
   
      >
        <i className="bi bi-globe"></i>
        <span className="ms-2">Pronunciations</span>
      </li>
      </NavLink>)}

      {permissionLogic(dataPermission, 'xlocModule') && 
      (<NavLink to={'/xloc'} exact="true" activeclassname="active">
        <li className={`d-flex align-items-center`}>
          <i className="xloc-icon"/>
          <span className="ms-2">XLOC</span>
        </li>
      </NavLink>)}

      {permissionLogic(dataPermission, 'showQATagsModule') && 
      (<NavLink to={'/qatags'} exact="true" activeclassname="active">
        <li
        className={`d-flex align-items-center`}
        

        
        >
          <i className="bi bi-bug"></i>
          <span className="ms-2">QA Tags</span>
        </li>
      </NavLink>)}

      {permissionLogic(dataPermission, 'UITemp') || 
        permissionLogic(dataPermission, 'createActors') ||
        permissionLogic(dataPermission, 'createTeams') ?
        <hr /> : <></>
      }


      {permissionLogic(dataPermission, 'UITemp') ?
      <NavLink to={'/admin'} className={
        adminLinks().includes(location.pathname) 
        ? 'active' 
        : ''
      }
        activeclassname="active">
        <li
          className={`d-flex align-items-center`} 
        >
          <i className="ri-chat-settings-line"></i>
          <span className="ms-2">Admin Panel</span>
        </li>
      </NavLink> : 
      <>{permissionLogic(dataPermission, 'createActors') &&
      (<NavLink to={'/prodresources'} exact="true" activeclassname="active">
        <li
          className={`d-flex align-items-center`}
          
  
          
        >
          <i className="bi bi-mic-fill"></i>
          <span className="ms-2">Actors</span>
        </li>
      </NavLink>)}
      {permissionLogic(dataPermission, 'createTeams') &&
      (<NavLink to={'/teams'} exact="true" activeclassname="active">
        <li
          className={`d-flex align-items-center`}
          
  
          
        >
          <i className="bi bi-people-fill"></i>
          <span className="ms-2">Resource Teams</span>
        </li>
      </NavLink>)}
            {permissionLogic(dataPermission, 'manageRecBooths') &&
      (<NavLink to={'/recbooths'} exact="true" activeclassname="active">
        <li
          className={`d-flex align-items-center`}
          
  
          
        >
          <i className="bi bi-box-fill"></i>
          <span className="ms-2">Recording Rooms</span>
        </li>
      </NavLink>)}
      </>}

       
      {permissionLogic(dataPermission, 'view_Scheduler') && 
      <NavLink to={'/scheduler'} exact="true" activeclassname="active">
        <li
          className={`d-flex align-items-center`}  
        >
          <i className="bi bi-bar-chart-steps"></i>
          <span className="ms-2">Scheduler</span>
        </li>
      </NavLink>}

      {permissionLogic(dataPermission, 'UITemp') && (
      <><hr />



      {/*<NavLink to={'/rolePermissions'} exact="true" activeclassname="active">
        <li
          className={`d-flex align-items-center`}
          
  
          
        >
          <i className="bi bi-people-fill"></i>
          <span className="ms-2">Roles Permission</span>
        </li>
      </NavLink>*/}
      <NavLink to={'/application'} exact="true" activeclassname="active">
        <li
          className={`d-flex align-items-center`}
          
  
          
        >
          <i className="bi bi-tropical-storm"></i>
          <span className="ms-2">Application</span>
        </li>
      </NavLink>
      <NavLink to={'/videos'} exact="true" activeclassname="active">
        <li
          className={`d-flex align-items-center`}
          
  
          
        >
          <i className="bi bi-camera-video-fill"></i>
          <span className="ms-2">Videos</span>
        </li>
      </NavLink>
      <hr />
      <NavLink to={'/chatai'}>
        <li
          className={`d-flex align-items-center`}
          
  
          
        >
          <i className="bi bi-person-x-fill"></i>
          <span className="ms-2">ChatGPT</span>
        </li>
      </NavLink>
      
      {<NavLink to={'/schedulerNew'} exact="true" activeclassname="active">
        <li
          className={`d-flex align-items-center`}  
        >
          <i className="bi bi-bar-chart-steps"></i>
          <span className="ms-2">Scheduler</span>
        </li>
      </NavLink>}
      <NavLink to={'/spreedsheet'} exact="true" activeclassname="active">
        <li
          className={`d-flex align-items-center`}
          
  
          
        >
          <i className="bi bi-arrow-90deg-right"></i>
          <span className="ms-2">Spreedsheet</span>
        </li>
      </NavLink>
      {/*<NavLink to={'/scheduler'} exact="true" activeclassname="active">
        <li
          className={`d-flex align-items-center`}
          
  
          
        >
          <i className="bi bi-arrow-90deg-down"></i>
          <span className="ms-2">Scheduler</span>
        </li>
      </NavLink>*/}
      </>)}
      <hr/>
      <NavLink to={'/help'} exact="true" activeclassname="active">
        <li
          className={`d-flex align-items-center`}
          
  
          
        >
          <i className="bi bi-info-circle"></i>
          <span className="ms-2">Help Online</span>
        </li>
      </NavLink>
      {internal === 1 && <NavLink to={'/metrics'} exact="true" activeclassname="active">
        <li
          className={`d-flex align-items-center`}
          
  
          
        >
          <i className="bi bi-speedometer"></i>
          <span className="ms-2">Metrics</span>
        </li>
      </NavLink>}
    </ul>
  </div>
  );

}

export const MenuLeft_old = () => {
  const menuhidden = parseUsername();



  if (ciao === 1) {
    return (
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <NavLink className="nav-link" to="/projects">
              <i className="bi bi-grid"></i>
              <span>Projects</span>
            </NavLink>
          </li>
          {!menuhidden.menutemp && (
          <li className="nav-item">
            <NavLink className="nav-link" to="/breakdown">
              <i className="bi bi-grid"></i>
              <span>DB TEMP</span>
            </NavLink>
          </li>)}
          {!menuhidden.menutemp && (
          <li className="nav-item">
            <NavLink className="nav-link" to="/script">
              <i className="bi bi-grid"></i>
              <span>Script</span>
            </NavLink>
          </li>)}


          <li className="nav-item">
            <NavLink className="nav-link" to="/scheduler">
              <i className="bi bi-grid"></i>
              <span>Scheduler</span>
            </NavLink>
          </li>
          


          {menuhidden.menutemp && (
            <>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  data-bs-target="#syncfusion-nav"
                  data-bs-toggle="collapse"
                  to="/"
                >
                  <i className="bi bi-menu-button-wide"></i>
                  <span>Temporany pages</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </NavLink>

                <ul
                  id="syncfusion-nav"
                  className="nav-content collapse "
                  data-bs-parent="#sidebar-nav"
                >
                  <li>
                    <NavLink to="/syncfusion">
                      <i className="bi bi-circle"></i>
                      <span>SimpleGrid</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/gantic">
                      <i className="bi bi-circle"></i>
                      <span>Gantic</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/gantic2">
                      <i className="bi bi-circle"></i>
                      <span>Gantic 2 </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/spreedsheet">
                      <i className="bi bi-circle"></i>
                      <span>Excel</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/assign">
                      <i className="bi bi-circle"></i>
                      <span>Assign</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/projectsdetails/10/bdbuilder">
                      <i className="bi bi-circle"></i>
                      <span>Assign 2</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/projectsdetails/8/gant">
                      <i className="bi bi-circle"></i>
                      <span>ProjectGant</span>
                    </NavLink>
                  </li>
                  
                </ul>
              </li>
            </>
          )}

          {/* studio management */}
          <li className="nav-item">
            <NavLink
              className="nav-link"
              data-bs-target="#studiomanagement"
              data-bs-toggle="collapse"
              to="/"
            >
              <i className="bi bi-menu-button-wide"></i>
              <span>Studio Management</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </NavLink>

            <ul
              id="studiomanagement"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <NavLink to="/resources_sync">
                  <i className="bi bi-circle"></i>
                  <span>Users and Roles</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/resources">
                  <i className="bi bi-circle"></i>
                  <span>Recording Rooms</span>
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </aside>
    );
  } else {
    return <div>Eccolo</div>;
  }
};



