import React,  {useState, useEffect} from 'react'
import axios from 'axios'
import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import AES256 from 'aes-everywhere';
import * as XLSX from 'xlsx';
import $ from 'jquery';
import path from 'path-browserify';
import { Link } from 'react-router-dom';

/* tasks icons */ 
import Precheck from '@mui/icons-material/PlaylistAddCheckCircleOutlined';
import Adaptation from '@mui/icons-material/AvTimerOutlined';
import Recording from '@mui/icons-material/MicOutlined';
import Postpro from '@mui/icons-material/SpeakerOutlined';
import Qa from '@mui/icons-material/VerifiedOutlined';
import Delivery from '@mui/icons-material/MarkEmailReadOutlined';

import InterpreterModeOutlinedIcon from '@mui/icons-material/InterpreterModeOutlined';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import TuneIcon from '@mui/icons-material/Tune';

import { saveAs } from 'file-saver';

// COMMET2

let pathBackend = ''
var versioning = "1.0.15"
let webSockedSource 
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"){
    console.log(`Localhost v${versioning}`)
    pathBackend = `http://localhost:80/audiomate/versions/${versioning}/`;
    webSockedSource = 'local'
    //pathBackend = `https://app.kantanaudiomate.com:8080/versions/${versioning}/`;
    //pathBackend = `https://dev.kantanaudiomate.com:8080/versions/${versioning}/`;

} else {
 
    if(window.location.hostname === "app.kantanaudiomate.com"){
      console.log(`REMOTE APP v${versioning}`)
      //console.log("Revision e")
      pathBackend = `https://app.kantanaudiomate.com:8080/versions/${versioning}/`;
      
    } else {
      console.log(`REMOTE DEV v${versioning}`)
      //pathBackend = `https://app.kantanaudiomate.com:8080/${versioning}/`;
      pathBackend = `https://dev.kantanaudiomate.com:8080/versions/${versioning}/`;
    }  
    webSockedSource = 'online'
}

export const AM_version = () => {
  return versioning
}

export const AM_isDev = () => {
  return window.location.hostname === "dev.kantanaudiomate.com"
}

export const parseUsername = () => {
    const captureData = JSON.parse(localStorage.getItem("user"));
    return captureData
}

/* take the information from the local storage for time formate*/
export const getTimeFormat = () => {
  try{
    const captureData = JSON.parse(localStorage.getItem("user"));
    if(captureData.timeformat12 === null || captureData.timeformat12 === undefined){
      return true
    }
    if(captureData.timeformat12 === "true"){
      return true
    } else {
      return false
    }
  } catch(e) {
    return true
  }
}

const creden = '{"AZURE_API_KEY":"","AZURE_ENDPOINT":""}'

function temporary(string){
  return AES256.encrypt(string.toString(), 'password')
}

var socketId = null;
export const AM_socketId = (set, id) => {
  if(set) { socketId = id };
  return socketId// ? socketId : 0;
}

export const EncparseUsername = () => {
    const captureData = localStorage.getItem("usertemp");
    return captureData
}

export const EncUserID = () => {
    const captureData = localStorage.getItem("usertemp");
    const userID = JSON.parse(AudioMateDecryption(captureData)).id
    return userID
}

export const extractValuesFromLocalStorage = () => {
  const captureData = localStorage.getItem("usertemp");
  const output = JSON.parse(AudioMateDecryption(captureData))
  return output
}

export const BASE_ADR = (apiAddress) => {
    return pathBackend + '?' + apiAddress + '&hc=7B3C48E252-DC18-4B4B-97C4-34975A6F12F1'
}

export const API_ADR = (apiAddress) => {
    //const loggedInUser = parseUsername();
    //const encryptedData = EncUserID()
    const encryptedData = localStorage.getItem("sessionID");
    return pathBackend + '?' + apiAddress + '&hc=7B3C48E252-DC18-4B4B-97C4-34975A6F12F1&userprotectionkey=' + encryptedData
    //return pathBackend + '?' + apiAddress + '&hc=7B3C48E252-DC18-4B4B-97C4-34975A6F12F1&userprotectionkey=' + loggedInUser.id
}

export const API_ADRWS = (type) => {
  const websocketKey = process.env.REACT_APP_WEBSOCKET_KEY ?? '';
  //if(webSockedSource === 'local') return `ws://localhost:8081/chat?&type=${type}&userprotectionkey=` + websocketKey 
  return `wss://wss.kantanaudiomate.com/chat?&type=${type}&userprotectionkey=` + websocketKey;
  //return 'ws://localhost:8081/chat?&userprotectionkey=' + websocketKey;
}

/*export const API_GET = (apiAddress) => {
    //const loggedInUser = parseUsername();
    const loggedInUser = EncUserID();
    const [data, setData] = useState([])

    const query = apiAddress + '&hc=7B3C48E252-DC18-4B4B-97C4-34975A6F12F1&userprotectionkey=' + loggedInUser

    useEffect(() => {
        axios.get(query).then(json => setData(json.data))
    // eslint-disable-next-line   
    }, [])
    return data
}*/

/* Badge status projects/batches/scripts */
export const ProjectStatus = (props) => {

    let title = `${props.pstatus.lineContent} "${props.pstatus.project_name}" `
    switch (parseInt(props.pstatus.status)) {
        case 0:
            return <span className="badge bg-primary" title={`${title} In Progress`}>In Progress</span>
        case 1:
            return <span className="badge bg-success" title={`${title} Closed`}>Closed</span>
        case -1:
            return <span className="badge bg-warning text-dark" title={`${title} Not in production`}>Not in production</span>
        default:
            return ""
    }
}


/* loading bar used to pass to the pages */
export const LoadingBar = (props) => {
    return (
        <ProgressBarComponent 
            id="linear"
            type="Linear"
            height="60"
            value={20}
            isIndeterminate={true}
            animation={{
                enable: true,
                duration: 2000,
                delay: 0
            }}>
        </ProgressBarComponent>
    )
}

export const ProgressionBar = (props) => {
    return (
        <ProgressBarComponent id="linear" type="Linear" height="60" value={props.value}>
        </ProgressBarComponent>
    )
}



let AudioMateModal
export const Modal = (props) => {
    const [status, setStatus] = React.useState({ hideDialog: false });
    // eslint-disable-next-line
    function handleClick() {
        setStatus({ hideDialog: true })
    }

    function dialogClose() {
        setStatus({ hideDialog: false })
    }


    return (
            <DialogComponent 
                ref={elem => AudioMateModal = elem}
                width='900px' visible = {status.hideDialog} close = {dialogClose}
                height='700px'
                showCloseIcon={true}
                >{props.content}
            </DialogComponent>

    );
}

export const ModalOpen = (props) => {
    /*AudioMateModal.content = props*/
    //AudioMateModal.refresh()
    AudioMateModal.show();
}

export const SetCssRoles = (props) => {
    let {variable, color, alpha} = props
    if(alpha === undefined){
      alpha = ''
    }
    document.documentElement.style.setProperty(`--${variable}`, color + alpha);
}

export const GetCssRoles = (props) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(props);
  //return document.documentElement.style.getProperty(`--${props}`);
}

const hexToRGBA = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgb(${r}, ${g}, ${b})`;
  };

export const SpinnerCentered = (props) => {

    return (

        <div className="modal fade show" id="AudioMateMenu" tabIndex="-1" aria-modal="true">
            <div className="modal-content" style={{"background": "none !important","border": "none", "top": '43%', "left": '50%', 'width': '68px'}}>
                <div className="modal-body" style={{"background": "none !important"}}>
                    <div className="spinner-border text-primary" role="status" style={{margin: '2px 4px 0px 2px'}}>
                    <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

/* check if the user has the permission to see a module */
export const permissionLogic = (permissionLogic, key) =>{
    if(permissionLogic[key] === undefined){
        return false
    } 
    return true
}

/* decode the string from server */
export const decodePermission = (string) => {
    
    let decodedPermissions = atob(string);
    return JSON.parse(decodedPermissions)
}

export const takePermissionLocalStorage = () => {
    const permissions = localStorage.getItem("pem");
    if(permissions === undefined || permissions === null){
        return {}
    }
    return decodePermission(permissions)
}

/* encryptation of the permission */

//const secret = process.env.REACT_APP_ENCPASSWORD;


export const AudioMateEncryption = (permission) => {
    const secret = localStorage.getItem("keyID");
    const encrypted = AES256.encrypt(permission.toString(), secret)
    return encrypted
}

export const AudioMateDecryption = (permission) => {
    const secret = localStorage.getItem("keyID");
    const decrypted = AES256.decrypt(permission, secret)
    return decrypted
}

export const automateQ = () => {
  const secret = localStorage.getItem("keyID");
  const decrypted = JSON.parse(AES256.decrypt(localStorage.getItem('system'), secret))
  return decrypted
}

export const automateV = (string) => {
  const secret = localStorage.getItem("keyID");
  const decrypted = AES256.decrypt(string, secret)
  return decrypted
}

/* validate password */

export const validatePassword = (password) => {
    const lengthRequirement = 12;
    const numberRegex = /\d/;
    const symbolRegex = /[!@#$%^&*()_+=~`[\]{}|:;"'<>,.?/\\-]/;
    const uppercaseRegex = /[A-Z]/;
  
    // Check the length requirement
    if(password.length < lengthRequirement) {
      return "The minimum password length is " + lengthRequirement + " characters.";
    }

    if(!numberRegex.test(password)){
        return "The password must contain at least one number.";
    }

    if(!symbolRegex.test(password)){
        return "The password must contain at least one symbol.";
    }

    if(!uppercaseRegex.test(password)){
        return "The password must contain at least one uppercase letter.";
    }

    // Check for at least one number, symbol, and uppercase letter
    /*if (!numberRegex.test(password) || !symbolRegex.test(password) || !uppercaseRegex.test(password)) {
      return false;
    }*/
  
    // All criteria are met
    return true;
  }

  /* lowercase */
  export const lowercaseString = (input) => {
    if (typeof input === 'string') {
      return input.toLowerCase();
    }
    return input;
  }

export const singularOrPlural = (count, singular, plural) => {
  if(count === 1){
    return singular
  } else {  
    return plural
  }
}

export const cleanString = (input) => {

  let str = String(input);
  let cleanedString = str.toLowerCase();
  cleanedString = cleanedString.replace(/[^\w\s]|_/g, "")
                               .replace(/\s+/g, "");

  return cleanedString;
}




export const manipulateString = (inputString, options = {}) => { 

    /*
    const options = {
        addPrefix: true,
        prefixString: "Greetings: ",
        addSuffix: true,
        suffixString: " - Have a nice day!",
        replaceString: "o",
        replaceWithString: "X",
        forceCase: "uppercase"
      };
    */

    let manipulatedString = inputString;
  
    if (options.addPrefix) {
      manipulatedString = options.prefixString + manipulatedString;
    }
  
    if (options.addSuffix) {
      manipulatedString += options.suffixString;
    }
  
    if (options.replaceString) {
      manipulatedString = manipulatedString.replace(new RegExp(options.replaceString, 'g'), options.replaceWithString);
    }
  
    if (options.forceCase === 'uppercase') {
      manipulatedString = manipulatedString.toUpperCase();
    } else if (options.forceCase === 'lowercase') {
      manipulatedString = manipulatedString.toLowerCase();
    }  
    return manipulatedString;
  }
  

  export const ExcelParser = (file) => {

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
    
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
   
          /*const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });*/
    
          resolve(workbook);
        };
    
        reader.onerror = (error) => {
          reject(error);
        };
    
        reader.readAsBinaryString(file);
      });
  };

/* handle errors */
function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div>
        <h2>Something went wrong:</h2>
        <p>{error.message}</p>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  }
  
  function useErrorBoundary() {
    const [error, setError] = useState(null);
  
    const resetErrorBoundary = () => {
      setError(null);
    };
  
    if (error) {
      return {
        hasError: true,
        error,
        resetErrorBoundary,
      };
    }
  
    return {
      hasError: false,
      error: null,
      resetErrorBoundary,
    };
  }
  
  export default function ErrorBoundary({ children }) {
    const { hasError, error, resetErrorBoundary } = useErrorBoundary();
  
    if (hasError) {
      return <ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />;
    }
  
    return children;
  }

  export const saveAsAM = async (blobData, fileName, options) => {
      try {
          const handle = await window.showSaveFilePicker(options);
          const writable = await handle.createWritable();
          await writable.write(blobData);
          await writable.close();
          return { success: true };
      } catch (err) {
          if (err.name === 'AbortError') {
              return { success: false, aborted: true };
          } else {
              saveAs(blobData, fileName);
              return { success: true, aborted: false };
          }
      }
  };




  /*export const ZendeskWidget = () => {
  useEffect(() => {
    // Function to get the Zendesk token
    const getZendeskToken = () => {
      return axios.post('http://localhost/audiomate/get_help_token/')
        .then((response) => {
          if (response.data.token) {
            return response.data.token;
          }
          return null;
        })
        .catch((error) => {
          console.error('Error fetching Zendesk token:', error);
          return null;
        });
    };

    // Configure the Zendesk widget
    const configureZendeskWidget = async () => {
      const token = await getZendeskToken();

      if (token) {
        window.zE('webWidget', 'hide');
        window.zE('webWidget:on', 'close', function() {
          window.zE('webWidget', 'hide');
        });
        window.zE.setLocale('en');
        window.zE.setHelpCenterSuggestions({ labels: ['default'] });

        // Set prefill data here if required
        window.zE('webWidget', 'prefill', {
          name: { value: 'Stefano Pocar', readOnly: false },
          email: { value: 'spocar@keywordsstudios.com', readOnly: false },
        });

        // Set the token for authentication
        window.zESettings = {
          authenticate: {
            jwtFn: function(callback) {
              callback(token);
            },
          },
        };

        // Load the Zendesk Web Widget script
        const script = document.createElement('script');
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=YOUR_ZENDESK_KEY';
        script.async = true;
        document.body.appendChild(script);
      }
    };

    configureZendeskWidget();
  }, []);

  return null; // We don't need to render anything for the Zendesk widget.
};*/

export const copyStyles = (sourceDoc, targetDoc) => {

  Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
      if (styleSheet.cssRules) { // for <style> elements
          const newStyleEl = sourceDoc.createElement('style');

          Array.from(styleSheet.cssRules).forEach(cssRule => {
              // write the text of each rule into the body of the style element
              newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
          });

          targetDoc.head.appendChild(newStyleEl);
      } else if (styleSheet.href) { // for <link> elements loading CSS from a URL
          const newLinkEl = sourceDoc.createElement('link');

          newLinkEl.rel = 'stylesheet';
          newLinkEl.href = styleSheet.href;
          targetDoc.head.appendChild(newLinkEl);
      }
  });
}


//Returns an array of allowed file formats for AudioMate.
export const allowedFileFormats = () => {
  return [".wav", ".mp3", ".ogg", ".flac", ".avi", ".aac", ".mov", ".mp4", ".wma", ".webm"]
}


/* generate random string */
export const generateRandomString = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

/* format date 12/24 hours */

export const AM_UserDateTime = (dateString) => {

  try{

  var is12HourFormat = getTimeFormat()

      // Array of abbreviated month names
    const abbreviatedMonths = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();

    let hours = date.getHours();
    let minutes = date.getMinutes();
  
    let hoursFormatted
    let minutesFormatted = minutes.toString().padStart(2, '0'); // Aggiungi uno zero se necessario

  
    if (is12HourFormat) {
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
      hoursFormatted = hours % 12 || 12; // Converti in formato 12 ore
      hoursFormatted = hoursFormatted.toString().padStart(1, '0'); // Aggiungi uno zero se necessario
      return `${day}-${abbreviatedMonths[month]}-${year} ${hoursFormatted}:${minutesFormatted} ${amOrPm}`;
    } else {
      hoursFormatted = hours.toString().padStart(2, '0');;
      return `${day}-${abbreviatedMonths[month]}-${year} ${hoursFormatted}:${minutesFormatted}`;
    }
  } catch(e) {
    return ""
  }
}

// Variabile globale per memorizzare il timezone dell'utente
let userTimezone = null;

// Funzione per ottenere il timezone con caching
const getUserTimezone = () => {
    if (!userTimezone) {
        userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return userTimezone;
}

export const AM_UserDateTimeTZ = (dateString) => {
  try {
    const is12HourFormat = getTimeFormat(); // Funzione che determina il formato orario
    const timezone = userTimezone ?? getUserTimezone(); // Recupera il timezone (cached)

    // Array di mesi abbreviati
    const abbreviatedMonths = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    // Crea un oggetto Date e convertilo nel timezone dell'utente
    const utcDate = new Date(dateString); // Data in UTC
    const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone: timezone }));

    // Ottenere i valori di giorno, mese, anno, ore e minuti
    let day = localDate.getDate();
    let month = localDate.getMonth();
    let year = localDate.getFullYear();

    let hours = localDate.getHours();
    let minutes = localDate.getMinutes();

    let hoursFormatted;
    const minutesFormatted = minutes.toString().padStart(2, '0'); // Aggiungi uno zero se necessario

    if (is12HourFormat) {
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
      hoursFormatted = hours % 12 || 12; // Converti in formato 12 ore
      return `${day}-${abbreviatedMonths[month]}-${year} ${hoursFormatted}:${minutesFormatted} ${amOrPm}`;
    } else {
      hoursFormatted = hours.toString().padStart(2, '0'); // Formato 24 ore
      return `${day}-${abbreviatedMonths[month]}-${year} ${hoursFormatted}:${minutesFormatted}`;
    }
  } catch (e) {
    console.error('Errore nella formattazione della data:', e);
    return '';
  }
};

/* script control height */
export const scriptControlHeight = (heightscreen) => {

  if(heightscreen === 'none'){
    heightscreen = window.innerHeight;
  }

  //check the top position of #scriptTable

  let basicscriptHeight = heightscreen 

  const element = $('#playerContainer');

  if(element !== undefined){

      if(element.is('.fullpage')){
          basicscriptHeight = basicscriptHeight - 50;
      }
  }

  const table = $('#scriptTable');
  if(table.length === 1){
    const position = table.offset();

    //if(position.top > 60){
      basicscriptHeight = basicscriptHeight - position.top - 50;
    //}

  }

  const fullscreenScript = $('#scriptcontainer.scriptfullscreen').length;
  if(fullscreenScript > 0){
      basicscriptHeight = basicscriptHeight + 30;
      if(element !== undefined){
          if(element.is('.fullpage')){
              basicscriptHeight = basicscriptHeight - 30;
          }
      }
  }

  document.documentElement.style.setProperty(`--div-height-basicscript`, basicscriptHeight + 'px');
  document.documentElement.style.setProperty(`--div-height-pronunciationspanel`, basicscriptHeight +  'px');
  document.documentElement.style.setProperty(`--div-height-scriptpronunciations`, (basicscriptHeight - 143) +  'px');

}

export const mediaControlHeight = () => {

  const viewport = window.innerHeight;
  const mediaCommandBar = $('#mediaCommandBar');
  if(mediaCommandBar.length === 1){
    let mediaCommandBarBottom = mediaCommandBar.offset().top + mediaCommandBar.outerHeight(true);

    const playerAtBottom = $('#playerContainer')
    if(playerAtBottom.length === 1 && playerAtBottom.is('.fullpage')){
      mediaCommandBarBottom = mediaCommandBarBottom - playerAtBottom.outerHeight(true) + 150;
    }
    $('#mediaTable').height(viewport - mediaCommandBarBottom - 50);
  }
}



// if the filename is a cinematic
export const isCinematic = (pathstring) => {
  const parsePath = path.parse(pathstring.toLowerCase()).ext
  const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv'];
  return videoExtensions.includes(parsePath);
}

/* remove dupllicates in key*/ 
export const removeDuplicates = (array, key) => {
  return array.reduce((acc, current) => {
    if (!acc.some(item => item[key] === current[key])) {
      acc.push(current);
    }
    return acc;
  }, []);
};

export const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    console.log('Text copied to clipboard successfully!');
  } catch (err) {
    console.error('Error copying to clipboard:', err);
  }
}

/* TASKS ICONS */
export const IconTask = (props) => {
  const iconInfo = props.icon.split('-')
  const type = iconInfo[0]
  let size
  if(iconInfo.length === 2){
    size = iconInfo[1]
  } else {
    size = 20
  }

  const style = { width: size, height: 'auto' }

  if(type === 'precheck'){
    return <Precheck style={style} />
  } else if(type === 'adaptation'){
    return <Adaptation style={style} />
  } else if(type === 'recording'){
    return <Recording style={style} />
  } else if(type === 'postpro'){
    return <Postpro style={style} />
  } else if(type === 'qa'){
    return <Qa style={style} />
  } else if(type === 'delivery'){
    return <Delivery style={style} />
  }
}

export const IconUserRole = (props) => {
  const iconInfo = props.icon.split('-')
  const type = iconInfo[0]
  let size
  if(iconInfo.length === 2){
    size = iconInfo[1]
  } else {
    size = 20
  }
  
  const style = {fontSize: size + 'px', width: size, height: 'auto' }

  if(type === 'director'){
    return <InterpreterModeOutlinedIcon style={style} />
  } else if(type === 'di___rector'){
    return <SettingsAccessibilityIcon style={style} />
  } else if(type === 'recording_engineer'){
    return <TuneIcon style={style} />
  } else if(type === 'recording_room'){
    return <i className="bi bi-box"></i>
  }
}
  


export const TextWithLinks = ({ text }) =>  {
  // Funzione per convertire il testo in elementi JSX
    const convertTextToLinks = (text) => {


      
      const linkRegex = /\[LINK\](.*?)\[\/LINK\]/g;
      let parts = [];
      let lastIndex = 0;

      // Trova tutti i match per i link nel testo
      text.replace(linkRegex, (match, url, index) => {
        // Aggiungi il testo prima del link
        parts.push(text.substring(lastIndex, index));

        // Aggiungi il link
        parts.push(<a href={url} title={url} target="_blank" rel="noreferrer" key={index}>[Link]</a>);

        // Aggiorna l'indice dell'ultimo carattere elaborato
        lastIndex = index + match.length;
      });

      // Aggiungi eventuali parti rimanenti del testo dopo l'ultimo link
      parts.push(text.substring(lastIndex));

    return parts;
  }

  if(text){
    return convertTextToLinks(text)
  }
}

/* css change text color based on background color */
export const getTextColorBasedOnBgColor = (hexColor) => {
  // Converti il colore hex in RGB
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);

  // Calcola la luminanza
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Se la luminanza è inferiore a 0.5, il testo dovrebbe essere bianco, altrimenti nero
  return luminance < 0.5 ? '#FFFFFF' : '#000000';
}

export const getBrowserName = () => {
  const userAgent = navigator.userAgent;

  // Elenco dei browser popolari e stringhe chiave nei loro User Agent
  if (userAgent.includes("Firefox")) {
    return "Firefox";
  } else if (userAgent.includes("Edg")) {
    return "Edge";
  } else if (userAgent.includes("OPR") || userAgent.includes("Opera")) {
    return "Opera";
  } else if (userAgent.includes("Chrome")) {
    return "Chrome";
  } else if (userAgent.includes("Safari")) {
    // Safari should be checked after Chrome because they both include the string "Safari"
    return "Safari";
  } else {
    return "Unknown Browser";
  }
}


export const AMModal = ({ children, ...props }) => {
  return <div className="modal show" id="AudioMateMenu" tabIndex="-1" aria-modal="true">
      <div className={`modal-dialog ${props.size ? `modal-${props.size} ` : '' }modal-dialog-centered modal-dialog-scrollable`} style={{
          opacity: props.opacity ?? null,
          width: props.width ?? null,
          height: props.height ?? null
      }}
      >
          <div className="modal-content" id="AudioMateWindowMenu" style={props.removeTop ? {top: '0px'} : {}}>
              <div className="modal-header" style={{padding: '0px 2px 0px 13px'}}>
                  <h5 className="modal-title">{props.header_title ?? '[No title]'}</h5>
                  {props.closeHandler && <button title={props.close_title ?? 'Close'} type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" onClick={props.closeHandler}>🗙</button>}    
              </div>
              {props.popupContent && <div style={{position: 'absolute', background: '#0000004d', width: '100%', height: '100%', zIndex: 9999}}>
                  <div className="modal-dialog modal-dialog-centered" style={{width: props.popupContentWidth ?? '80%', }}>
                      <div className="modal-content">
                          {props.popupShowheader && <div className="modal-header" style={{padding: '3px 8px 0px 11px'}}>
                              <h6 className="modal-title">{props.popupTitle ?? 'Warning title'}</h6>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.popupClose}></button>
                          </div>}
                          <div className="modal-body" style={{padding: '0px 8px 8px 11px'}}>
                            {props.popupContent}
                          </div>
                          {props.popupButton && <div className="modal-footer p-1" style={{display: 'flex', justifyContent: 'center'}}>
                              {props.popupButton}
                          </div>}
                      </div>
                  </div>  
              </div>}
              <div className={`modal-body${props.contentClass ? ' ' + props.contentClass : ''}`} style={{overflowY: props.overflowY ?? 'unset', fontSize: props.bodyFontSize ?? null, height: props.contentHeight ?? null}}>
                  {children}   
              </div>
              <hr style={{margin: 0}}/>
                  <div className="text-center pt-2 pb-2">
                      {props.buttons} 
                  </div>
          </div> 
      </div>
  </div>
}

export const AMSearch = (props) => {

  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
      let timeout;
      const handleSearch = () => {
          // Qui puoi inserire la tua logica di ricerca
          props.onSearch(inputValue.trim())
      };

      timeout = setTimeout(() => {
          handleSearch();
      }, 500);

      return () => {
          clearTimeout(timeout);
      };
  // eslint-disable-next-line
  }, [inputValue]);

  const searchValue = (e) => {
      setInputValue(e.target.value)
  }

  return <input type="search" className="form-control searchtext" placeholder="Search..." value={inputValue}

      style={inputValue === '' ? {width: '165px', float: 'right', height: '30px'}
                              : {width: '165px', float: 'right', height: '30px', background: '#ff00003b'}} onChange={searchValue} spellCheck={false} />
}


export const dBConn = async (address, data, method = 'post') => {
  const link = API_ADR(address);
  try {
      const response = await axios({
          method, // Use the dynamic method (get, post, etc.)
          url: link,
          data: method === 'post' ? data : null, // Only include data for POST
          headers: { 'Content-Type': 'text/plain' }
      });
      //console.log(response.data);
      return response.data;
  } catch (error) {
      console.log("ERROR", error);
      return null;
  }
};

// Attach GET and POST methods to the dBConn object
dBConn.get = (address) => dBConn(address, null, 'get');
dBConn.post = (address, data) => dBConn(address, data, 'post');


export const AMStats = (module, description, values = 1) => {

    // examples 
    //AMStats('media', 'access'); // just access
    //AMStats('media', 'download', 3568); // just counters

    dBConn.post("am-stats", {module, description, values}).then((response) => {
      //console.log(response);
  })

}